import { OnboardingService } from './../../../shared/services/onboarding.service';
import { SharedOwnerDashboardService } from './../../../shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { SharedSfxService } from './../../../shared/services/shared-sfx.service';
import { UserWebsitesResult } from './dto/portal.output';
import { SharedPortalService } from './shared-portal.service';
import { VideoModel } from './../../../models/portal-video';
import { PaymentService } from '../../../shared/services/payment.service';
import { map, share } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ViewChildren,
  QueryList,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { User } from './../../../models';
import {
  StepViewModel,
  UserWebsite,
  PortalContentEnum,
  MailServiceStatus,
  PortalWarningEnum,
} from './portal.model';
import Swal from 'sweetalert2';
import { Validators } from '@angular/forms';
import {
  checkEmailRegex,
  checkSiteNameRegex,
} from '../../../shared/services/regex';
import { HttpErrorResponse } from '@angular/common/http';

import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  stagger,
  keyframes,
} from '@angular/animations';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPopover,
} from '@ng-bootstrap/ng-bootstrap';

import { async } from 'rxjs/internal/scheduler/async';

import { Subscription, throwError, lastValueFrom } from 'rxjs';
import { AuthenticationService, ApiService } from '../../../shared/services';
import { checkPortalRegex } from '../../../shared/services/regex';
import { NamecheapUserBalanceResponse } from 'src/app/models/namecheap';
import {
  GetBtcRateResult,
  PaymentMethodModel,
  PaymentServiceInterface,
  RateTypeEnum,
} from 'src/app/models/payment.model';
import * as introJs from 'intro.js';
import {
  FilteringTypeModel,
  PageItem,
  RoleModel,
  WebsiteUpdate,
} from 'src/app/models/portal';
import { ProfileUiControlService } from '../../../shared/services/profile-ui-control.service';
import { PortalControlService } from '../../../shared/services/portal-control.service';
import {
  NumbersOfDomainsAndPortalsForUser,
  NumbersOfDomainsOrPortalsForUser,
  SubscriptionBillingInfo,
} from '../../../models/subscription';

import { environment } from 'src/environments/environment';
import { UserDashboardService } from '../../../shared/services/user-dashboard.service';
import { SortingTypeModel } from '../../../models/portal';

import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Confirm, Report } from 'notiflix';
import { FirstModalTypeEnum } from './first-portal-modal/first-modal.component';

declare var btcpay: any;
declare var IntroJs: any;

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: [
    './portal.component.css',

    './portaltable.component.css',
    './portalcredit.component.css',
  ],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimateList', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0s 200ms ease-in'),
      ]),

      transition(':leave', [animate('200ms ease-out', style({ opacity: 1 }))]),
    ]),
    trigger('AnimateListLOnly', [
      transition(':leave', [
        animate('200ms ease-out', style({ opacity: 0.5 })),
      ]),
    ]),
    trigger('filterAnimation', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        animate(
          200,
          keyframes([
            style({ opacity: 0, transform: 'translateX(-10px)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
          ])
        ),
      ]),
      transition('* => void', [
        animate(
          200,
          keyframes([
            style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
            style({ opacity: 0, transform: 'translateX(-10px)', offset: 1.0 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class PortalComponent implements OnInit, OnDestroy {
  processingPortal = false;
  firstModalType: FirstModalTypeEnum = FirstModalTypeEnum.tour;
  onboardingModalOpener: EventEmitter<any> = new EventEmitter<any>();
  userRole: RoleModel;
  onLoadingDoneSub: Subscription;
  onDeletePortal: Subscription;
  onPortalCreateSub: Subscription;

  @ViewChildren('p1') public popover: QueryList<NgbPopover>;
  public stepViewModel: StepViewModel;

  isPortalTab: boolean = true;
  isCoursesTab: boolean = true;
  isTutorialsTab: boolean = false;
  isFirstPortal: { isFirstWebsite: boolean } = { isFirstWebsite: false };

  introJSCurrentStep: 0;
  portalContentEnum: PortalContentEnum = PortalContentEnum.Dashboard;
  mailServiceStatus: MailServiceStatus = MailServiceStatus.not_configured;
  startLoading: boolean;
  private domainUrl: string;
  // shareArr: any;
  descArr: any;
  loaded = false;
  error = '';
  currentUser: User;
  closeResult: string;
  userMailOptionsSize: number;
  userMailOptions: any;

  isFirstWebsite = false;
  isIntro = false;
  isTour = false;
  isTourDomain = false;
  isLoading = false;
  userWebsitePages: PageItem[];

  // Sort And Filtering Features
  searchWord: string = '';
  sortObject: SortingTypeModel;
  sortTypes: SortingTypeModel[] = [
    { displayName: 'Oldest', column: 'createdAt', order: 'asc' },
    { displayName: 'Newest', column: 'createdAt', order: 'dsc' },
    { displayName: 'Recently Updated', column: 'test', order: 'asc' },
    { displayName: 'A-Z Title', column: 'portalName', order: 'asc' },
    { displayName: 'Z-A Title', column: 'portalName', order: 'dsc' },
  ];

  filterObject: FilteringTypeModel = {
    index: 0,
    displayName: 'All',
    column: '',
    value: '',
  };
  filterTypes: FilteringTypeModel[] = [
    { index: 0, displayName: 'All', column: '', value: '' },
    {
      index: 1,
      displayName: 'Sales Funnel',
      column: 'template.name',
      value: 'salesFunnel',
    },
    {
      index: 2,
      displayName: 'Webinar',
      column: 'template.name',
      value: 'webinar',
    },
    {
      index: 3,
      displayName: 'Opt-in',
      column: 'template.name',
      value: 'optIn',
    },
  ];
  // Sort And Filtering Features

  pagesScreenshots: string[] = [];
  subscriptionInfo: SubscriptionBillingInfo;
  subscriptionWarning: PortalWarningEnum = PortalWarningEnum.none;

  isProduction: boolean = environment.production;
  selectedService = 1;
  selectedPaymentMethod: string;
  PaymentMethodsData: PaymentMethodModel[] = [];

  tourSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private api: ApiService,
    private paymentService: PaymentService,
    private modalService: NgbModal,
    private route: Router,
    private aroute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public profileUiService: ProfileUiControlService,
    public portalControlService: PortalControlService,
    
    public userDashboardService: UserDashboardService,
    public sharedPortalService: SharedPortalService,
    public sharedSfxService: SharedSfxService,
    private sharedOwnerDashboardService: SharedOwnerDashboardService,
    private onboardingService: OnboardingService
  ) {
    // this.callScreenShot = this.callScreenShot.bind(this);
    this.handleGetSubscriptionBundleInfoError =
      this.handleGetSubscriptionBundleInfoError.bind(this);
    this.handleNamecheapBalanceError =
      this.handleNamecheapBalanceError.bind(this);
    this.handleGetPaymentMethodsError =
      this.handleGetPaymentMethodsError.bind(this);
    this.handleGetBtcRateError = this.handleGetBtcRateError.bind(this);
    this.handleFirstPortalError = this.handleFirstPortalError.bind(this);

    this.init();
  }

  async openOnboardingModal() {
    this.firstModalType = FirstModalTypeEnum.tour;
    this.onboardingModalOpener.emit();
    // await this.finishFirstTour();
  }

  // async finishFirstTour() {
  //   const request$ = this.authenticationService.finsihUserTour();
  //   const request = await lastValueFrom(request$);
  // }

  onStartTour(value: any) {
    
    setTimeout(() => {
      if (this.firstModalType == FirstModalTypeEnum.tour) {
        switch (value) {
          case 'startTour':
            this.onboardingService.startCreateFirstPortalTour(true);
            break;
          case 'close':
            this.onboardingService.showHelpCenterTour(true);
            break;

          default:
            break;
        }
      } else if (this.firstModalType == FirstModalTypeEnum.portal) {
        this.onboardingService.startHavePortalTour(true);
      }
    }, 500);
  }

  firstPortalOnboarding() {
    this.firstModalType = FirstModalTypeEnum.portal;
    this.onboardingModalOpener.emit();
    this.portalControlService.isFirstWebsite = false;
  }

  timeConverter(timeStamp: number) {
    const a = new Date(timeStamp * 1000);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours() < 10 ? '0' + a.getHours() : a.getHours();
    const min = a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes();
    const sec = a.getSeconds() < 10 ? '0' + a.getSeconds() : a.getSeconds();
    const time = date + ' ' + month + ' ' + year;
    return time;
  }

  sortPortals(order: 'asc' | 'dsc' = 'asc', column: string) {}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  doSetTimeoutForDelete(userWebsiteID: number) {
    setTimeout(async () => {
      this.sharedPortalService.userWebsites.websites[
        userWebsiteID
      ].process.percentage = 105;

      setTimeout(async () => {
        this.sharedPortalService.userWebsites.websites.splice(userWebsiteID, 1);
      }, 500);
    }, 1000);
  }

  // Sort And Filtering Features
  sortTypeChange(sortObject: SortingTypeModel) {
    this.sortObject = sortObject;
  }

  filterTypeChange(filterObject: FilteringTypeModel) {
    this.filterObject = filterObject;
  }

  onSearchWordChange(value: any) {
    this.searchWord = value;
  }

  // Sort And Filtering Features
  private init() {



    // this.onLoadingDoneSub = this.socketService.onDeleteDoneEvent.subscribe((a: WebsiteUpdate)=>{
    //   this.sharedPortalService.userWebsites.websites = this.sharedPortalService.userWebsites.websites.filter(item => !(item.siteId === a.id));

    //   this.cdr.markForCheck();
    //   this.cdr.detectChanges();
    // });
  }

  scrollTo(elementID: string) {
    (document.getElementById(elementID) as HTMLElement)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  async isThisFirstPortal() {
    const request$ = this.api.firstPortal(this.handleFirstPortalError);
    const request = await lastValueFrom(request$);

    return request;
  }
  openModal() {}

  async getMyRole() {
    this.userRole = await this.api.getUserRole().toPromise();
  }

  firstWebsiteAlert() {
    setTimeout(async () => {
      Swal.fire({
        title: 'Congratulations!',
        html: 'You have created your first Portal You are now ready to start customizing it! We recommend you take a moment to learn the ropes of the <b>learmo Editor. You can find a lot of help in our TUTORIAL LIBRARY</b>',
        icon: 'success',
        buttonsStyling: false,
        customClass: {
          cancelButton: 'btn btn-lg btn-outline btn-outline-primary mx-1',
          confirmButton: 'btn btn-lg btn-primary mx-1',
        },
        showCloseButton: false,
        reverseButtons: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Tutorials',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText: 'Dismiss',
        cancelButtonAriaLabel: 'Thumbs down',
      }).then((result) => {
        if (result.isConfirmed) {
          this.goToTutorials();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // this.FirstPortalintroMethod();
          this.onboardingService.startHavePortalTour();
          // TODO: PORTAL INNER TOUR
        }

        this.portalControlService.isFirstWebsite = false;
      });
    }, 50);
  }

  goToTutorials() {
    this.route.navigate(['/dashboard/tutorials']);
  }
  // firstLoginAlert() {
  //   Swal.fire({
  //     title: 'Welcome to learmo!',
  //     html: 'Let’s take a tour!',
  //     icon: 'success',
  //     buttonsStyling: false,
  //     customClass: {
  //       cancelButton: 'btn btn-lg btn-outline btn-outline-primary mx-1',
  //       confirmButton: 'btn btn-lg btn-primary mx-1',
  //     },
  //     showCloseButton: false,
  //     reverseButtons: true,
  //     showCancelButton: true,
  //     focusConfirm: true,
  //     focusCancel: false,
  //     confirmButtonText: 'Start Tour',
  //     confirmButtonAriaLabel: 'Thumbs up, great!',
  //     cancelButtonText: 'Dismiss',
  //     cancelButtonAriaLabel: 'Thumbs down',
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       Swal.close();
  //       this.isPortalTab = true;
  //       setTimeout(async () => {
  //         this.onboardingService.startGeneralTour(true);
  //       }, 500);
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       this.onboardingService.showHelpCenterTour(true);
  //     }

  //     const finishThisTour = await this.authenticationService
  //       .finsihUserTour()
  //       .toPromise();
  //   });
  // }
  paragraphHover(paragraphID: string) {
    // State True Mouse Over
    // State False Mouse Left
    this.descArr[paragraphID] = !this.descArr[paragraphID];
  }

  submitMyPageForm(i) {
    const myform = document.getElementById(
      'myCheckWebsiteForm' + i
    ) as HTMLFormElement;
    myform.submit();
  }

  encodeRedirect(url) {
    return encodeURIComponent(url);
  }

  async ngOnDestroy() {
    this.onboardingService.unlockPortalDropDown();
    // this.socketService.closeUserSocket();
    this.onLoadingDoneSub?.unsubscribe();
    this.onPortalCreateSub?.unsubscribe();
    this.onDeletePortal?.unsubscribe();
    this.tourSubscription?.unsubscribe();
  }

  handleGetSubscriptionBundleInfoError(error: any) {}

  changeThisBundle() {
    this.route.navigate(['dashboard/profile/billing-and-subscription/subscription']);
  }

  async getSubscriptionInfo() {
    this.subscriptionInfo = await this.api
      .getSubscriptionBundleInfo(this.handleGetSubscriptionBundleInfoError)
      .toPromise();

    const BundleEndTime = new Date(
      this.subscriptionInfo.subscriptionData.endDate * 1000
    );

    // Grace Period Warning
    if (
      this.subscriptionInfo.subscriptionData.currentSubscriptionType.toString() ===
        'grace' &&
      !!this.subscriptionInfo.subscriptionData.graceEndDate
    ) {
      this.subscriptionWarning = PortalWarningEnum.grace_warning;
    }
    // One Day Remaining Warning
    else {
      let TimeDiff = Math.floor(
        (BundleEndTime.getTime() - new Date().getTime()) / 1000
      );
      if (TimeDiff <= 86400) {
        this.subscriptionWarning = PortalWarningEnum.one_day_warning;
      }
    }
  }

  async ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    await this.getMyRole();
    await this.getMailingData();
    this.isFirstPortal = await this.isThisFirstPortal();

    // await this.sharedPortalService.getUserPortals();
    this.loaded = true;

    // this.socketService.createListData.push(
    //   {
    //     error: { code: 15, message: 'Error' },
    //     id: 3393233352,
    //     percentage: 100,
    //     portalName: 'dasdqw',
    //     processType: 1,
    //     step: 'Deleting certificate renewals',
    //     template: 'crimsonoptin_1.0.tgz',
    //     url: 'eqwwq.myinfluencerportal.com'
    //   }
    // )
    // const sub = this.aroute.params.subscribe(async params => {
    //   const id = +params['id']; // (+) converts string 'id' to a number
    //   const domainRoute = params['id'];

    //   if (!!id && id > 0 && id <= this.sharedPortalService.userWebsites.websites.length) {
    //     this.loaded = false;
    //     this.sharedPortalService.userWebsites.choosedWebsite = id - 1;

    //     if (!this.sharedPortalService.userWebsites.websites[id - 1].isFree) {
    //       if (this.sharedPortalService.userWebsites.websites[id - 1].isLinkedWithEmailService && this.sharedPortalService.userWebsites.websites[id - 1].isVerified && this.userMailOptionsSize > 0) {
    //         this.mailServiceStatus = MailServiceStatus.activated;
    //       }
    //       else if (this.sharedPortalService.userWebsites.websites[id - 1].isLinkedWithEmailService && !this.sharedPortalService.userWebsites.websites[id - 1].isVerified && this.userMailOptionsSize > 0) {
    //         this.mailServiceStatus = MailServiceStatus.pending;
    //       }
    //       else if (!this.sharedPortalService.userWebsites.websites[id - 1].isLinkedWithEmailService && !this.sharedPortalService.userWebsites.websites[id - 1].isVerified && this.userMailOptionsSize > 0) {
    //         this.mailServiceStatus = MailServiceStatus.configured;
    //       }
    //       else {
    //         this.mailServiceStatus = MailServiceStatus.not_configured;
    //       }
    //     }
    //     // TODO: Configuration Wordpress Pages API Call
    //     this.portalControlService.changePortalContent(PortalContentEnum.Website);
    //     this.userWebsitePages = await this.api.getWebsitePages(this.sharedPortalService.userWebsites.websites[id-1].siteId.toString()).toPromise();
    //     this.userWebsitePages.sort((a,b) => a.id - b.id);
    //     // this.userWebsitePages = WebsitePages.sort((a,b) => a.id - b.id);

    //     this.screenShotsQueue();
    //     this.loaded = true;
    //     // this.userDashboardService.internalLoaded = true;
    //   }
    //   else if (this.route.url.includes('domains')) {
    //     // this.onChooseDomains();
    //     this.loaded = true;
    //     // this.userDashboardService.internalLoaded = true;
    //   }
    //   else if (this.route.url.includes('tutorials')) {
    //     this.onChooseTutorials();
    //     this.loaded = true;
    //     // this.userDashboardService.internalLoaded = true;
    //   }
    //   else if (this.route.url.includes('create')) {
    //     this.changePortalContent(2);
    //     this.loaded = true;
    //     // this.userDashboardService.internalLoaded = true;
    //   }
    //   else if (this.route.url.includes('courses')) {
    //     this.onChooseCourses();
    //     this.loaded = true;
    //     // this.userDashboardService.internalLoaded = true;
    //   }
    //   else {
    //     // this.route.navigate(['dashboard', 'portal']);
    //     this.loaded = true;
    //     // this.userDashboardService.internalLoaded = true;
    //   }

    // });

    if (!this.currentUser.finishedTour) {
      this.openOnboardingModal();
    }

    this.tourSubscription = this.userDashboardService.tourEmitter.subscribe(
      (a) => {
        this.repeatTour();
      }
    );

    

    await this.getSubscriptionInfo();

    if (!!this.portalControlService.isFirstWebsite) {
      this.firstWebsiteAlert();
    }

    await this.sharedPortalService.getCountOfSubscriptionPermissionsPortalsAndDomains();
  }

  scroll() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  subscribeSocketFlow() {}

  // async screenShotsQueue() {
  //   for (const i of this.userWebsitePages) {
  //     const url = await this.sharedPortalService.callScreenShot(
  //       i.link.replace(/^https?:\/\//, ''),
  //       true
  //     );
  //     this.pagesScreenshots.push(url);
  //   }
  // }

  getWebsiteScreenshot(url: string) {
    return (
      'https://api.microlink.io?url=' +
      url +
      '&screenshot=true&meta=false&embed=screenshot.url'
    );
  }

  paymentErrorHandler(error: any) {}

  private paymentSuccessAlert(transactionID: string) {
    Swal.fire({
      title: 'Great!',
      text: 'Purchased Successfully!',
      icon: 'success',
      confirmButtonText: 'Continue',
    }).then((res) => {
      this.modalService.dismissAll();
      this.route.navigate([
        '/verification/domain/' +
          // Todo: GetDomain Alternate
          '/' +
          transactionID,
      ]);
    });
  }

  private paymentErrorAlert() {
    Swal.fire({
      title: 'Something Went Wrong!',
      text: 'Error',
      icon: 'error',
    });
  }

  public fireStopLoadingSwalError(message) {
    this.isLoading = false;
    Swal.fire({
      title: 'Error!',
      text: message.toString(),
      icon: 'error',
      confirmButtonText: 'OK',
    }).then((res) => {
      return false;
    });
  }
  handleCreateWebsiteError(error: any) {
    this.error = error.message;
  }

  toggleWithGreeting(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  async getMailingData() {
    const data = await this.api.getUserMailServices().toPromise();
    const userMailService: Map<
      number,
      { mailServiceId: number; mailServiceName: string; apiKey: string }
    > = data.data;
    this.userMailOptions = new Map();

    for (const key in userMailService) {
      if (Object.prototype.hasOwnProperty.call(userMailService, key)) {
        const a = userMailService[key];
        this.userMailOptions.set(a.mailServiceId, a.mailServiceName);
      }
    }
    this.userMailOptionsSize = this.userMailOptions.size;
  }

  async setDomainMailingService() {
    const portalData =
      this.sharedPortalService.userWebsites.websites[
        this.sharedPortalService.userWebsites.choosedWebsite
      ];

    if (this.mailServiceStatus == MailServiceStatus.not_configured) {
      this.route.navigateByUrl('profile/mailing');
    } else {
      Swal.fire({
        icon: 'success',
        title: 'All done!',
        confirmButtonText: 'OK!',
      });
      let mailingEmail = '';
      Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger m-1',
          cancelButton: 'btn btn-success m-1',
        },
        confirmButtonText: 'Next &rarr;',
        showCancelButton: true,
        progressSteps: ['1', '2'],
        reverseButtons: true,
        cancelButtonColor: '#dc3545',
        title: 'SMTP Account Linking',
      })
      // .queue([
      //   {
      //     html:
      //       'Choose an outgoing email for this Domain.<br>This is the email that your subscribers<br>will see in the FROM field <div class="input-group mt-3"><select style="height: 3.2em;" id="mailingEmail" class="form-control" placeholder="Username" aria-describedby="basic-addon2"><option value="noreply">noreply</option><option value="newsletter">newsletter</option><option value="subscribe">subscribe</option><option value="marketing">marketing</option></select><div class="input-group-append"><span class="input-group-text" id="basic-addon2">' +
      //       '@' +
      //       portalData.siteUrl.replace('www.', '') +
      //       '</span></div>',
      //     preConfirm: () => {
      //       mailingEmail =
      //         (document.getElementById('mailingEmail') as HTMLInputElement)
      //           .value +
      //         '@' +
      //         portalData.siteUrl.replace('www.', '');
      //     },
      //   },
      //   {
      //     html: 'Pick a SMTP Account to use for this Domain<br>You can Configure additional accounts under <a href="profile/mailing">SMTP Settings</a>',
      //     input: 'select',
      //     inputOptions: this.userMailOptions,
      //     allowOutsideClick: false,
      //     inputAttributes: {
      //       style:
      //         'height: 2.7em;border-radius: 5px;border: 1px solid #ced4da;',
      //       autocapitalize: 'off',
      //     },
      //     confirmButtonText: 'Proceed',
      //     showLoaderOnConfirm: true,
      //     preConfirm: (mailingService) => {
      //       return this.api
      //         .updatePortalMail(
      //           +portalData.siteId,
      //           +mailingService,
      //           mailingEmail,
      //           this.handleErrorMailing
      //         )
      //         .toPromise()
      //         .then((response) => {
      //           this.mailServiceStatus = MailServiceStatus.pending;
      //           Swal.fire({
      //             icon: 'success',
      //             title: 'All done!',
      //             confirmButtonText: 'OK!',
      //           });
      //         })
      //         .catch((error) => {
      //           Swal.showValidationMessage(`Request failed: ${error}`);
      //         });
      //     },
      //   },
      // ]);
    }
  }

  showDomainLimitAlert() {
    Swal.fire({
      allowOutsideClick: true,
      title: "We're Sorry",
      html: `Your current plan doesn't support purchasing more than <b>${this.sharedPortalService.numbersOfDomainsAndPortalsForUser.domains.max}</b> domains`,
      icon: 'warning',
      confirmButtonText: 'Ok',
    }).then((res) => {
      if (res.value) {
      }
    });
  }



  // deletePortalConfirmation(index: number,callback = ()=>{} ) {
  //   Report.failure(
  //     'Read Carefully',
  //     `<span>I'm sure that i want to delete my portal</span><b> ${this.sharedPortalService.userWebsites.websites[index].portalName}</b>, and I understand that:<br><b class='text-danger'>this step cannot be undone</b>`,
  //     `I Understand`,
  //     ()=> {
  //       callback();
  //     },
  //     {plainText: false}
  //     );
  // }
  // deleteSiteErrorAlert(index: number) {
  //   this.deletePortalConfirmation(index, ()=>{
  //     Confirm.ask(
  //       'You’re about to Delete your Portal',
  //       `Are you sure you want to Delete</span><br><b> ${this.sharedPortalService.userWebsites.websites[index].portalName}</b>
  //       <br>
  //       Type <b class='text-danger'>Delete</b> to confirm
  //       `,
  //       'Delete',
  //       'Delete',
  //       'Cancel',
  //       ()=>{
  //         alert('Delete')
  //       },
  //       ()=>{
  //         // alert('Cancel')
  //       },
  //       {
  //         okButtonColor: '#fff',
  //         okButtonBackground: '#F64E60',
  //         titleColor: '#F64E60',
  //         plainText: false,
  //         messageMaxLength: 99999,
  //         // backOverlayColor: 'rgba(255,85,73,0.2)'
  //       }
  //     )
  //   });
  // }

  // toggleShare(index: number) {
  //   this.shareArr[index] = !this.shareArr[index];
  // }

  chooseWebsite(id: number) {
    this.route.navigate(['dashboard', 'portal', 'view', id, 'design']);
    this.sharedOwnerDashboardService.setBackRoute('Portals');
  }

  objsize(obj) {
    var size = 0,
      key;

    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  }

  /* Copy To Clipboard */
  // async copyUserurl(val: string, ID: string) {
  //   const selBox = document.createElement('textarea');
  //   selBox.style.position = 'fixed';
  //   selBox.style.left = '0';
  //   selBox.style.top = '0';
  //   selBox.style.opacity = '0';
  //   selBox.value = val;
  //   document.body.appendChild(selBox);
  //   selBox.focus();
  //   selBox.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(selBox);

  //   const objectID = 'copytoClipboard' + ID;
  //   const copyBut = document.getElementById(objectID);
  //   copyBut.setAttribute('style', 'background: rgb(69, 224, 152)');
  //   copyBut.innerText = 'Done !';

  //   await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000));
  //   this.restoreCopyuserurl(objectID);
  // }

  // restoreCopyuserurl(objectID: string) {
  //   const copyBut = document.getElementById(objectID);
  //   copyBut.setAttribute('style', 'background: #fff');
  //   copyBut.innerText = 'Copy to Clipboard';
  // }

  onEditEmailClicked() {
    this.sharedPortalService.userWebsites.isEditingEmail = true;
  }
  onFinishEditEmail(email: string) {
    this.sharedPortalService.userWebsites.isInputEmailValid = true;
    if (checkEmailRegex(email)) {
      this.sharedPortalService.userWebsites.email = email;
      this.sharedPortalService.userWebsites.isEditingEmail = false;
    } else {
      this.sharedPortalService.userWebsites.isInputEmailValid = false;
    }
    // check if valid email
  }
  onCancelEditEmail() {
    this.sharedPortalService.userWebsites.isEditingEmail = false;
  }

  changeStep(stepIndex: number, isBackFromFinalStep: boolean = false) {
    this.stepViewModel.changeStep(stepIndex, isBackFromFinalStep);
  }

  private handleError(error: HttpErrorResponse) {
    const msg = 'Something went wrong in portal service.';
    // return an observable with a user friendly message
    return throwError(error.message || msg);
  }

  private handleErrorMailing(error: any) {
    const msg = 'Something went wrong in portal service.';
    // return an observable with a user friendly message
    return throwError(error.message || msg);
  }

  async checkNamecheapBalance() {
    const response: NamecheapUserBalanceResponse = await this.api
      .getNamecheapBalance()
      .toPromise();
    return response;
  }

  private handleNamecheapBalanceError(error: any) {}

  showBTC(invoiceID: string) {
    window.location.href =
      'https://mainnet.demo.btcpayserver.org/i/' + invoiceID;
  }

  private handleGetBtcRateError(error: any) {}

  navigateToVerification() {
    this.route.navigate(['profile', 'account']);
  }

  repeatTour() {
    // this.portalControlService.changePortalContent(0);
    setTimeout(async () => {
      this.onboardingService.startGeneralTour(true);
    }, 700);
  }

  async getAllPaymentMethodsForUser(sorted: boolean = true) {
    const paymentMethods: PaymentMethodModel[] = await this.api
      .getAllPaymentMethod(sorted, this.handleGetPaymentMethodsError)
      .toPromise();

    if (paymentMethods.length > 0) {
      this.selectedPaymentMethod = paymentMethods[0].id;
    }
    this.PaymentMethodsData = paymentMethods;
  }

  private handleFirstPortalError(error: any) {}

  private handleGetPaymentMethodsError(error: any) {}
}
