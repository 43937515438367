import { Chat } from './chat.service';
export default function groupChatsByDate (chats: Chat[]): { title: string; chats: Chat[] }[] {
    const specialGroups: { [key: string]: Chat[] } = {
      Today: [],
      Yesterday: [],
      "Previous 30 days": [],
    };
    const monthGroups: { [key: string]: Chat[] } = {};
    const yearGroups: { [key: string]: Chat[] } = {};

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to start of the day
    const yesterday = new Date(today.getTime());
    yesterday.setDate(yesterday.getDate() - 1);
    const thirtyDaysAgo = new Date(today.getTime());
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    chats.forEach((chat) => {
      const chatDate = new Date(chat.date);
      chatDate.setHours(0, 0, 0, 0); // Normalize to start of the day

      if (isSameDay(chatDate, today)) {
        specialGroups["Today"].push(chat);
      } else if (isSameDay(chatDate, yesterday)) {
        specialGroups["Yesterday"].push(chat);
      } else if (chatDate > thirtyDaysAgo) {
        specialGroups["Previous 30 days"].push(chat);
      } else {
        const year = chatDate.getFullYear();
        const monthName = getMonthName(chatDate.getMonth());

        if (year === today.getFullYear()) {
          if (!monthGroups[monthName]) monthGroups[monthName] = [];
          monthGroups[monthName].push(chat);
        } else {
          if (!yearGroups[year]) yearGroups[year] = [];
          yearGroups[year].push(chat);
        }
      }
    });

    // Combine and sort the groups
    const combinedGroups = [
      ...Object.keys(specialGroups)
        .filter((key) => specialGroups[key].length > 0)
        .map((key) => ({ title: key, chats: specialGroups[key] })),
      ...Object.keys(monthGroups)
        .sort(
          (a, b) =>
            new Date(`${b} 1, ${today.getFullYear()}`).getTime() -
            new Date(`${a} 1, ${today.getFullYear()}`).getTime()
        )
        .map((month) => ({ title: month, chats: monthGroups[month] })),
      ...Object.keys(yearGroups)
        .sort((a, b) => parseInt(b) - parseInt(a))
        .map((year) => ({ title: year.toString(), chats: yearGroups[year] })),
    ];

    return combinedGroups;
  }

  function getMonthName(monthIndex: number): string {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthIndex];
  }

  function isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }