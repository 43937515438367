// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-404 {
  width: 100vw;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/app/components/not-found-page/not-found-page.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".container-404 {\n  width: 100vw;\n  height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
