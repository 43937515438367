import { Pipe, PipeTransform } from '@angular/core';
import { unslugify } from './utils/unslugify.utils';

@Pipe({
  name: 'unslugify'
})
export class UnslugifyPipe implements PipeTransform {
  transform(value: any): string {
    return unslugify(value);
  }
}
