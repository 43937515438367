import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-country-flag',
  templateUrl: './global-country-flag.component.html',
  styleUrls: ['./global-country-flag.component.css']
})
export class GlobalCountryFlagComponent implements OnInit {

  countryCode: string;
  @Input('countryCode') set setCountryCode(value: string) {
    this.src = `assets/flags/${value.toLowerCase}.png`;
    this.countryCode = value.toLowerCase();
  }

  src = 'assets/flags/unknown.png';
  constructor() { }

  ngOnInit() {
  }

}
