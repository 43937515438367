import { PlanDataTransfer } from './plans-container/plan-item/plan-item.component';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Bundle, CouponPlansResult, Plan } from 'src/app/models/plans';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ApiService, UserService } from '../../shared/services';
import { PaymentService } from '../../shared/services/payment.service';
import Swal from 'sweetalert2';
import {
  OntTimePaymentOutputBtcpay,
  PaymentMethodModel,
} from 'src/app/models/payment.model';
import { ConfirmCardPaymentData, loadStripe } from '@stripe/stripe-js';
import { Subscription } from 'src/app/models/user';
import { SubscriptionBillingInfo } from 'src/app/models/subscription';
import { trigger, transition, style, animate } from '@angular/animations';
import { HostListener } from '@angular/core';

export enum SubscribtionWizard {
  choose_plan,
  choose_method,
  checkout,
}

export enum UserSubscriptionStatus {
  subscribe,
  upgrade,
}

export enum PlansPurchaseModeEnum {
  annual = 'annual',
  monthly = 'monthly',
}
@Component({
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ChoosePlanComponent implements OnInit {
  plansPurchaseModeEnum = PlansPurchaseModeEnum;

  currentPlan: number;
  currentPlanIndex: number;
  iagreecard: boolean;
  annualPlans: Bundle[];
  monthlyPlans: Bundle[];
  priceInBTC = 0;
  userSubscriptionStatus: UserSubscriptionStatus =
    UserSubscriptionStatus.subscribe;
  enableNavigation = true;
  enableMonthly = true;
  enableAnnually = true;
  btcExpand = false;
  cardElement: any;
  payPlan: SubscribtionWizard = SubscribtionWizard.choose_plan;
  haveCoupon = false;
  couponID: number = undefined;
  couponCode = '';
  // annual = true;
  @Input() plansPurchaseMode: PlansPurchaseModeEnum =
    PlansPurchaseModeEnum.annual;

  selectedBundle: number = null;
  selectedBundleId: number = null;
  isLoading = false;
  isCouponeLoading = false;
  stripeAgreement = false;
  isCouponError = false;
  couponeErrorMessage = '';
  saveCard = false;
  PaymentMethodsData: PaymentMethodModel[];
  addNewCard = true;
  selectedPaymentMethod: number;
  selectedPaymentCard: string;
  changePaymentMethod = true;
  bundleTypeParam: string;
  isBack: boolean = false;

  // Query Params
  username: string;
  email: string;

  constructor(
    private authenticationService: AuthenticationService,
    public userService: UserService,
    private api: ApiService,
    private paymentService: PaymentService,
    private router: Router,
    private aroute: ActivatedRoute,
    public cdr: ChangeDetectorRef
  ) {
    this.bundleTypeParam = this.aroute.snapshot.queryParams['bundleType'];
    this.selectedBundleId = this.aroute.snapshot.queryParams['bundleID'];
    this.username = this.aroute.snapshot.queryParams['uname'];
    this.email = this.aroute.snapshot.queryParams['email'];
    // console.group('info Data',this.email, this.username);
    this.isBack = this.aroute.snapshot.queryParams['isBack'];

    // if (this.isBack) {
    //   this.route.navigate(['dashboard', 'portal']);
    // }

    if (this.selectedBundleId !== undefined) {
      this.router.navigate(['register'], {
        queryParams: {
          bundleID: this.selectedBundleId,
          bundleType: this.bundleTypeParam,
          uname: this.username,
          email: this.email,
        },
      });
    }

    this.handleGetPlansError = this.handleGetPlansError.bind(this);
    this.handleVerifyCouponError = this.handleVerifyCouponError.bind(this);
    this.handleGetSubscriptionBundleInfoError =
      this.handleGetSubscriptionBundleInfoError.bind(this);
    this.handleGetPaymentMethodsError =
      this.handleGetPaymentMethodsError.bind(this);
    this.handleSubscribeUserError = this.handleSubscribeUserError.bind(this);
    this.handleConvertUsdToBtcError =
      this.handleConvertUsdToBtcError.bind(this);
  }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   location.href = 'https://www.myinfluencerportal.com/#register';
  //   return false;
  // }

  handleGetPaymentMethodsError(error: any) {}
  resetWizard() {
    document.getElementById('paynowcontainer').classList.add('checkout-fix');
    this.payPlan = SubscribtionWizard.choose_plan;
    // this.selectedBundle = null;
  }

  choosePlan(
    bundle: number,
    plansPurchaseMode: PlansPurchaseModeEnum = PlansPurchaseModeEnum.annual
  ) {
    // true = Annual
    // false = Monthly
    switch (plansPurchaseMode) {
      case PlansPurchaseModeEnum.annual:
        this.bundleTypeParam = 'a';
        break;
      case PlansPurchaseModeEnum.annual:
        this.bundleTypeParam = 'm';
        break;
      default:
        break;
    }

    this.selectedBundle = bundle;
    //
  }

  handleSubscribeUserError(error: any) {}

  async getAllPaymentMethodsForUser(sorted: boolean = true) {
    const paymentMethods: PaymentMethodModel[] = await this.api
      .getAllPaymentMethod(sorted, this.handleGetPaymentMethodsError)
      .toPromise();

    if (paymentMethods.length > 0) {
      this.PaymentMethodsData = paymentMethods;

      this.selectedPaymentCard = paymentMethods[0].id;
      this.cdr.markForCheck();
    }
  }

  purchasePlan(data: PlanDataTransfer) {
    this.choosePlan(data.itemId, data.mode);
    // this.payPlan = SubscribtionWizard.choose_method;
    this.router.navigate(['register'], {
      queryParams: {
        bundleID: this.selectedBundle,
        bundleType: this.bundleTypeParam,
        uname: this.username,
        email: this.email,
      },
    });
    // if (this.userSubscriptionStatus === UserSubscriptionStatus.subscribe) {
    // }
  }

  async subscribeWithBtc() {
    const Swalbtc = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success btn-lg m-1',
        cancelButton: 'btn btn-danger m-1',
      },
      buttonsStyling: false,
      reverseButtons: false,
    });

    const terms = `
<ul class='text-start' style='max-height: 300px;overflow-y: scroll;'>
  <li class='pb-3'>The money back guarantee trial shall last for a period of 14 days only. After the 14 day trial, the account will no longer be eligible for a refund.</li>
  <li class='pb-3'>Customers signing up for the trial using the BTC+Alt Coins method understand they are responsible to submit their payments in a timely manner to avoid the suspension of the service,</li>
  <li class='pb-3'>Customers not wishing to continue the Service once the money back guarantee trial ends must cancel the Service using the Cancel Account Form located in the User's Account Settings before the trial period ends and provide the required information to viabilice the refund. </li>
  <li class='pb-3'>Offer applies to new customers only who select the 14 Day Money back guarantee Trial option when singing up to a service plan.</li>
  <li class='pb-3'>Offer might not be available for customers in all regions and countries.</li>
  <li class='pb-3'>Notice will be sent if payment fails for any reason. It is the customers responsibility to make sure payment details are correct to avoid suspension of service.</li>
  <li class='pb-3'>learmo reserves the right to remove or cancel the trial offer at any time.</li>
  <li class='pb-3'>learmo reserves the right to change the features of the trial offer at any time.</li>
  <li class='pb-3'>learmo General Terms and Conditions apply.</li>
  <li class='pb-3'>learmo reviews all orders and reserves the right to deny or cancel service at any time and for any reason.</li>
</ul>
`;

    Swalbtc.fire({
      title: 'Crypto Payment Terms of Use',
      input: 'checkbox',
      inputPlaceholder:
        'I agree to follow these terms and understand that I am the sole responsible if I ignore their use.',
      inputValidator: (result) => {
        return !result && 'You need to agree with Crypto Payment Terms of Use.';
      },
      width: '45%',
      html: terms,
      icon: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: 'CONTINUE',
      cancelButtonText: 'CANCEL',
      didOpen: function () {
        Swalbtc.getConfirmButton().setAttribute('disabled', 'disabled');
        Swalbtc.getConfirmButton().classList.add('btn-dark');
        const input = Swalbtc.getInput();
        input.onchange = (event) => {
          if (!!(event.target as HTMLInputElement).checked) {
            Swalbtc.getConfirmButton().removeAttribute('disabled');
            Swalbtc.getConfirmButton().classList.remove('btn-dark');
          } else {
            Swalbtc.getConfirmButton().setAttribute('disabled', 'disabled');
            Swalbtc.getConfirmButton().classList.add('btn-dark');
          }
        };
      },
    }).then(async (result) => {
      if (result.value) {
        this.startLoading();
        let bundleId: number;
        switch (this.plansPurchaseMode) {
          case PlansPurchaseModeEnum.annual:
            bundleId = this.annualPlans[this.selectedBundleId].id;
            break;
          case PlansPurchaseModeEnum.monthly:
            bundleId = this.monthlyPlans[this.selectedBundleId].id;
            break;
          default:
            break;
        }
        const response: OntTimePaymentOutputBtcpay = await this.paymentService
          .subscriptionBtcPay(bundleId, +this.couponID)
          .toPromise();
        this.stopLoading();
        window.location.href = response.invoiceUrl;
      }
    });
  }

  async loadStripeCard() {
    const SwalTerms = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success m-1',
        cancelButton: 'btn btn-danger m-1',
        title: 'display-4 text-center',
      },
      buttonsStyling: false,
    });

    const terms = `
    <ul class='text-start' style='max-height: 300px;overflow-y: scroll;'>
    <li class='pb-3'>The free trial shall last for a period of 14 days only. After the 14 days free trial, the regular price will be charged for a new month of service in the chosen plan. </li>
    <li class='pb-3'>Customers signing up for the free trial using a Credit Card will be required to submit payment card details as part of the process, to allow automatic payments once the free trial period finishes. </li>
    <li class='pb-3'>Customers not wishing to continue the Service once the free trial ends must cancel the Service using the Cancel Account Form located in the User's Account Settings before the trial period ends. A reminder email will be sent 2 days before the first payment is due. </li>
    <li class='pb-3'>Offer applies to new customers only who select the 14 Day Free Trial option when signing up for a service plan. </li>
    <li class='pb-3'>Offer might not be available for customers in all regions and countries. </li>
    <li class='pb-3'>Notice will be sent if the payment fails for any reason. It is the customers' responsibility to make sure payment details are correct to allow successful renewal payments and avoid suspension of the service. </li>
    <li class='pb-3'>learmo reserves the right to remove or cancel the free trial offer at any time. </li>
    <li class='pb-3'>learmo reserves the right to change the features of the free trial offer at any time. </li>
    <li class='pb-3'>learmo General Terms and Conditions apply. </li>
    <li class='pb-3'>learmo reviews all orders and reserves the right to deny or cancel service at any time and for any reason.</li>
    </ul>
    `;

    SwalTerms.fire({
      allowOutsideClick: false,
      title: '14 Days Trial Terms and Conditions',
      width: '75%',
      html: terms,
      confirmButtonText: 'I Agree',
      cancelButtonText: 'Disagree',
      showLoaderOnConfirm: true,
      showCancelButton: true,
    }).then(async (res) => {
      if (!!res.isDismissed) {
        return false;
      } else {
        SwalTerms.close();
      }
      // this.startLoading();
      this.payPlan = SubscribtionWizard.checkout;
      this.stopLoading();
    });
  }

  startLoading() {
    this.isLoading = true;
  }
  stopLoading() {
    this.isLoading = false;
  }

  async errorSubscription() {
    this.stopLoading();
    Swal.fire({
      title: 'Something Went Wrong!',
      text: 'Error',
      icon: 'error',
    });
  }
  async doneSubscription() {
    setTimeout(() => {
      this.fireStopLoadingSwal();
      this.stopLoading();
    }, 2000);
  }
  private fireStopLoadingSwal() {
    Swal.fire({
      title: 'Great!',
      text: 'Your Membership is started!',
      icon: 'success',
      confirmButtonText: 'Go To Dashboard',
    }).then((res) => {
      this.router.navigate(['dashboard', 'portal']);
    });
  }

  public fireStopLoadingSwalError(message) {
    this.stopLoading();
    Swal.fire({
      title: 'Error!',
      text: message.toString(),
      icon: 'error',
      confirmButtonText: 'OK',
    }).then((res) => {
      return false;
    });
  }

  selectPaymentMethod(id: number) {
    this.selectedPaymentMethod = id;
  }

  async payWithStripe() {
    this.startLoading();
    const subscribe: { clientSecret: string } = await this.paymentService
      .subscriptionStripe(
        this.selectedBundleId,
        this.selectedPaymentCard,
        undefined,
        this.handleSubscribeUserError
      )
      .toPromise();
    await this.paymentService.loadStripe();
    const { error, paymentIntent } =
      await this.paymentService.stripe.confirmCardPayment(
        subscribe.clientSecret
      );
    if (!!paymentIntent) {
      this.doneSubscription();
    } else {
      this.errorSubscription();
    }
    // this.stopLoading()
  }

  handleGetSubscriptionBundleInfoError(error: any) {}
  async ngOnInit() {
    if (!!this.authenticationService.currentUserValue) {
      this.router.navigate(['profile', 'subscription']);
    }
    // this.authenticationService.forget()
    await this.getPlans();
    // await this.getAllPaymentMethodsForUser();

    if (!!this.selectedBundleId && !!this.bundleTypeParam) {
      if (this.bundleTypeParam == 'a') {
        // this.annual = true;
        this.plansPurchaseMode = PlansPurchaseModeEnum.annual;
      } else if (this.bundleTypeParam == 'm') {
        this.plansPurchaseMode = PlansPurchaseModeEnum.monthly;
        // this.annual = false;
      }
      this.purchasePlan({
        itemId: this.selectedBundleId,
        mode: this.plansPurchaseMode,
      });
    }
  }

  // public async doneAddPayment() {
  //   setTimeout(async () => {
  //     await this.getAllPaymentMethodsForUser();
  //     // this.changePaymentMethod = true;
  //     // this.addNewCard = false;
  //     this.payWithStripe();
  //   }, 2000);
  // }

  public async doneAddPaymentAndSubscribed() {
    // if (!!paymentIntent) {
    this.doneSubscription();
    // }
    // else {
    //   this.errorSubscription();
    // }
  }

  switchToMonthlyPlan() {
    this.selectedBundle = null;
    this.plansPurchaseMode = PlansPurchaseModeEnum.monthly;
  }

  switchToAnnualPlan() {
    this.selectedBundle = null;
    this.plansPurchaseMode = PlansPurchaseModeEnum.annual;
  }

  public async getPlans() {
    const plans = await this.api
      .getPlans(this.handleGetPlansError)
      .toPromise();
    this.monthlyPlans = plans.monthlyBundles;
    this.annualPlans = plans.annualBundles;
    this.couponID = undefined;
    if (this.monthlyPlans.length <= 0 || this.annualPlans.length <= 0) {
      this.enableNavigation = false;
      if (this.monthlyPlans.length <= 0) {
        this.enableMonthly = false;
        this.switchToAnnualPlan();
      } else if (this.annualPlans.length <= 0) {
        this.enableAnnually = false;
        this.switchToMonthlyPlan();
      }
    } else {
      this.enableNavigation = true;
    }
  }

  private handleGetPlansError(error: any) {
    Swal.fire({
      title: 'Something Went Wrong!',
      text: 'Error',
      icon: 'error',
    });
  }

  public async verifyCoupon() {
    this.isCouponeLoading = true;
    const couponPlans: CouponPlansResult = await this.api
      .verifyCoupone(this.couponCode, this.handleVerifyCouponError)
      .toPromise();
    // this.monthlyPlans = couponPlans.monthlybundles;
    // this.annualPlans = couponPlans.annualbundles;
    this.couponID = couponPlans.couponId;
    if (this.monthlyPlans.length <= 0 || this.annualPlans.length <= 0) {
      this.enableNavigation = false;
      if (this.monthlyPlans.length <= 0) {
        this.switchToAnnualPlan();
      } else if (this.annualPlans.length <= 0) {
        this.switchToMonthlyPlan();
      }
    } else {
      this.enableNavigation = true;
    }

    this.isCouponeLoading = false;
    this.haveCoupon = false;
  }
  private handleVerifyCouponError(error: any) {
    this.isCouponeLoading = false;

    if (error.statusCode === 630) {
      this.isCouponError = true;
      this.couponeErrorMessage = error.message;
    }
  }

  // async subscribeWithBtc() {
  //   const bundleId = this.annual ? this.annualPlans[this.selectedBundle].id : this.monthlyPlans[this.selectedBundle].id;
  //   const response: OntTimePaymentOutputBtcpay = await this.paymentService.subscriptionBtcPay(bundleId, +this.couponID).toPromise();
  //   window.location.href = response.invoiceUrl;
  // }

  async convertUsdToBtc(priceInUsd: number = 0) {
    switch (this.plansPurchaseMode) {
      case PlansPurchaseModeEnum.annual:
        priceInUsd = this.annualPlans[this.selectedBundle].price;
        break;
      case PlansPurchaseModeEnum.monthly:
        priceInUsd = this.monthlyPlans[this.selectedBundle].price;
        break;
      default:
        break;
    }

    const { priceInBtc } = await this.api
      .convertUsdToBtc(priceInUsd, this.handleConvertUsdToBtcError)
      .toPromise();
    this.priceInBTC = priceInBtc;
  }

  private handleConvertUsdToBtcError(error: any) {}
}
