import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Country } from 'src/app/models/country';
import { getWebsiteMainAnalyticsActions } from 'src/app/shared/store/_features/website/analytics/website-analytics.actions';
import { isWebsiteAggregateAnalyticsLoaded, selectWebsiteAggregateAnalytics } from './../../../../../../shared/store/_features/website/analytics/website-analytics.selector';
import { UserWebsite, WebsiteModel } from '../../../portal.model';
import { selectWebsite } from 'src/app/shared/store/_features/website/website.selector';

@Component({
  selector: 'app-website-aggregate-analytics-bar',
  templateUrl: './website-aggregate-analytics-bar.component.html',
  styleUrls: ['./website-aggregate-analytics-bar.component.css'],
})
export class WebsiteAggregateAnalyticsBarComponent implements OnInit {
  loaded: boolean = false;
  website: WebsiteModel;
  analytics: {
    bestCountry: { country: string; countryItem: Country; visitors: number };
    bounce_rate: any;
    pageviews: any;
    visit_duration: any;
    visitors: any;
    visits: any;
    realtimeVisitors: number;
  };
  constructor(private store: Store) {
    this.store.select(selectWebsite).subscribe((website) => {
      this.website = website;
    });
    

    this.store.select(selectWebsiteAggregateAnalytics).subscribe((analytics) => {
      this.analytics = analytics;
    });

    this.store.select(isWebsiteAggregateAnalyticsLoaded).subscribe((loaded) => {
      this.loaded = loaded;
    });
  }

  ngOnInit() {
    
  }
}
