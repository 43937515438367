import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Message } from '../../../../services/chat.service';

@Component({
  selector: 'app-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.css'],
})
export class UserMessageComponent {
  @Input() message!: Message;
  @Input() layout: 'chat-bubbles' | 'centered' = 'chat-bubbles';
  @Output() messageEdited = new EventEmitter<Message>();
  @ViewChild('myTextarea') myTextarea!: ElementRef;

  isEditing: boolean = false;
  editableMessage: string = '';

  constructor() {}

  activateEdit() {
    this.editableMessage = this.message.text;
    this.isEditing = true;
    setTimeout(() => this.autoResizeTextarea());
  }

  saveEdit() {
    this.messageEdited.emit({ ...this.message, text: this.editableMessage });
    this.isEditing = false;
  }

  cancelEdit() {
    this.editableMessage = '';
    this.isEditing = false;
  }

  autoResizeTextarea(): void {
    const textarea: HTMLTextAreaElement = this.myTextarea.nativeElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
}
