// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
    transition: height 0.2s;
    overflow-y: auto;
    max-height: 300px;
    min-height: 20px;
  }
  #dropdownTopButton {
    position: relative;
  }
  #dropdownTop {
    position: absolute;
    bottom: 68px;
    right: 40px;
    z-index: 10;
  }


  .send-button {
    opacity: 0;
    max-width: 0;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
  }

  .send-button.show {
    max-width: 50px;
    opacity: 1;
  }
  .send-button:hover {
    transform: scale(1.1) translateY(-5px);
  }`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/chat-with-ai/partials/chat-input/chat-input.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;EAClB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,WAAW;EACb;;;EAGA;IACE,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,iCAAiC;EACnC;;EAEA;IACE,eAAe;IACf,UAAU;EACZ;EACA;IACE,sCAAsC;EACxC","sourcesContent":["textarea {\n    transition: height 0.2s;\n    overflow-y: auto;\n    max-height: 300px;\n    min-height: 20px;\n  }\n  #dropdownTopButton {\n    position: relative;\n  }\n  #dropdownTop {\n    position: absolute;\n    bottom: 68px;\n    right: 40px;\n    z-index: 10;\n  }\n\n\n  .send-button {\n    opacity: 0;\n    max-width: 0;\n    overflow: hidden;\n    transition: all 0.15s ease-in-out;\n  }\n\n  .send-button.show {\n    max-width: 50px;\n    opacity: 1;\n  }\n  .send-button:hover {\n    transform: scale(1.1) translateY(-5px);\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
