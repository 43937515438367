import { Router } from '@angular/router';
import { SharedProfileService } from './../../../../components/profile/shared-profile.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learmo-subscription-widget',
  templateUrl: './learmo-subscription-widget.component.html',
  styleUrls: ['./learmo-subscription-widget.component.css']
})
export class LearmoSubscriptionWidgetComponent implements OnInit {
  loaded: boolean = false;
  constructor(public sharedProfileService: SharedProfileService, public router: Router) { }

  async ngOnInit() {
    this.loaded = false;
    await this.sharedProfileService.getSubscriptionInfo()
    this.loaded = true;
  }



}
