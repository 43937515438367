import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  OnChanges,
  OnInit,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-bot-message',
  templateUrl: './bot-message.component.html',
  styleUrls: ['./bot-message.component.css'],
})
export class BotMessageComponent implements OnInit, OnChanges {
  @Input() message: string;
  @Input() type: string;
  messageCopy: string;
  @Input() layout: 'chat-bubbles' | 'centered' = 'chat-bubbles';
  @Input() links?: Array<{ title: string; url: string }>;
  @Output() openViewer = new EventEmitter<string>();
  parsedBlocks: Array<{ content: string; isCode: boolean; language?: string }> =
    [];

  constructor(private clipboard: Clipboard) {
    this.message = '';
    this.messageCopy = '';
    this.links = [];
    this.type = 'text';
  }

  ngOnInit() {
    this.parseMessage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['message'] && !changes['message'].firstChange) {
      this.parseMessage();
    }
  }

  parseMessage() {
    const lines = this.message.split('\n');
    let currentBlock: { content: string; isCode: boolean; language?: string } =
      { content: '', isCode: false };
    this.parsedBlocks = [];

    for (const line of lines) {
      if (line.trim().startsWith('```')) {
        if (currentBlock.content) {
          this.parsedBlocks.push({ ...currentBlock });
        }
        if (!currentBlock.isCode) {
          // Starting a new code block
          const lang = line.trim().slice(3).trim();
          currentBlock = {
            content: '',
            isCode: true,
            language: lang || undefined,
          };
        } else {
          // Ending a code block
          currentBlock = { content: '', isCode: false };
        }
      } else {
        currentBlock.content += line + '\n';
      }
    }

    if (currentBlock.content) {
      this.parsedBlocks.push(currentBlock);
    }
  }

  copyCode(code: string) {
    this.clipboard.copy(code);
    // Optionally, you can add a toast notification here to indicate successful copy
  }
}
