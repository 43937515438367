import { Payment_Gateway_Enum } from './portal';
import { MoneyModel } from './sales-funnel';

export class TransactionsListResponse {
  currentPage: number;
  countInCurrentPage: number;
  totalItems: number;
  totalPages: number;
  transactions: BillingLogItem[];
}

export class BillingLogItem {
  id: number;
  invoiceId: string;
  productType: Product_Type_Enum;
  productDetails: string;
  price: MoneyModel;
  paidAmount: MoneyModel;
  paymentMethod: Payment_Gateway_Enum;
  transactionDate: number;
}

export enum Product_Type_Enum {
  domain = 'domain',
  subscription = 'subscription',
}

export class BillingLogFilter {
  type?: Product_Type_Enum;
  page?: number;
  itemsPerPage?: number;
}
