
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BillingLogItem } from 'src/app/models/billing-log';

@Component({
  selector: 'app-transaction-info-popup',
  templateUrl: './transaction-info-popup.component.html',
  styleUrls: ['./transaction-info-popup.component.css'],
})
export class TransactionInfoPopupComponent implements OnInit {
  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalOpener: EventEmitter<any>;

  @Input() transaction: BillingLogItem;

  @Output() onModalEvent: EventEmitter<any> = new EventEmitter<any>();

  modalRef: NgbModalRef;
  modalOpenerSubscription: Subscription;
  isOpen: boolean = false;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService
  ) {}
  ngOnDestroy(): void {
    // this.modalOpenerSubscription.unsubscribe();
  }

  ngOnInit(): void {}

  async onOpenModal() {
    this.resetEverything();

    // this.resetForm();
    // this.productObject.type_id = 1;
  }

  closeModal() {
    this.modalRef.dismiss();
    
    this.onModalEvent.emit('close');
  }
  resetEverything() {}

  onStartTour() {
    this.onModalEvent.emit('startTour');
    this.modalRef.dismiss();
  }

  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: true,
      centered: true,
      animation: false,
windowClass: 'global-modal-class modal-animate-in invoice-modal',
    });
    this.isOpen = true;
    this.modalRef.result.then(
      (result) => {
        this.isOpen = false;
      },
      (reason: ModalDismissReasons) => {
        this.isOpen = false;
      }
    );
  }
}
