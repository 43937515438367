// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .user-drop-button, .user-drop-button img.symbol {
  border-radius: 15px;
} */
.user-drop-button {
  /* background-color: var(--bs-primary) !important; */
  /* padding: 1px; */
}

.user-drop-button[aria-expanded="true"],
.user-drop-button[aria-expanded="false"]:hover {
  /* background-color: var(--bs-primary-light) !important; */
}

/* button[aria-expanded="false"]:hover,
button[aria-expanded="true"] {
  background-color: #ebebeb !important;
} */
`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/user-drop/user-drop.component.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;EACE,oDAAoD;EACpD,kBAAkB;AACpB;;AAEA;;EAEE,0DAA0D;AAC5D;;AAEA;;;GAGG","sourcesContent":["/* .user-drop-button, .user-drop-button img.symbol {\n  border-radius: 15px;\n} */\n.user-drop-button {\n  /* background-color: var(--bs-primary) !important; */\n  /* padding: 1px; */\n}\n\n.user-drop-button[aria-expanded=\"true\"],\n.user-drop-button[aria-expanded=\"false\"]:hover {\n  /* background-color: var(--bs-primary-light) !important; */\n}\n\n/* button[aria-expanded=\"false\"]:hover,\nbutton[aria-expanded=\"true\"] {\n  background-color: #ebebeb !important;\n} */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
