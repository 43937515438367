import { ApiService } from './../../../../services/lsg.api.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  delay,
  map,
  mergeMap,
  switchMap,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, forkJoin, interval, lastValueFrom, Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';


import {findCountryByCodeOrName} from '../../../../data/countries.data'
import { getWebsiteMainAnalyticsActions } from './website-analytics.actions';

@Injectable()
export class WebsiteAnalyticsEffects {
  isPolling = true;
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) { }

 



  // Analytics
  loadWebsiteAggregateAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWebsiteMainAnalyticsActions.load),
      mergeMap(() =>
        forkJoin({
          aggregateAnalytics: this.api.getWebsiteAggregateAnalytics(()=> {throw new Error('') }),
          bestCountry: this.api.getWebsiteBestTrafficCountry(()=> {throw new Error('') }),
          realtimeVisitors: this.api.getWebsiteRealtimeCurrentVisitors(()=> {throw new Error('') }), // Initial load of realtime visitors
        }).pipe(
          map((response: { 
            aggregateAnalytics: { results: any }, 
            bestCountry: { results: { country: string, visitors: number }[] }, 
            realtimeVisitors: number 
          }) => {
            const bestCountry = response.bestCountry?.results?.length
              ? { ...response.bestCountry.results[0], countryItem: findCountryByCodeOrName(response.bestCountry.results[0].country) }
              : undefined;
  
            return getWebsiteMainAnalyticsActions.loadSuccess({
              stats: {
                ...response.aggregateAnalytics.results,
                bestCountry,
                realtimeVisitors: response.realtimeVisitors // Set initial realtime visitors
              }
            });
          }),
          catchError((error) =>
            of(getWebsiteMainAnalyticsActions.loadFailure({ error }))
          )
        )
      )
    )
  );
  


// Polling for Realtime Visitors
startPollingRealtimeVisitors$ = createEffect(() =>
  this.actions$.pipe(
    ofType(getWebsiteMainAnalyticsActions.load), // Start polling after initial load success
    switchMap(() => this.pollRealtimeVisitors()) // Start long polling
  )
);


// Helper function for long polling
pollRealtimeVisitors(): Observable<any> {
  return this.api.getWebsiteRealtimeCurrentVisitors(()=> {throw new Error('') }).pipe(
    // When the request completes, dispatch an action with the new data
    map((realtimeVisitors: number) => {
      console.log('realtimeVisitors', realtimeVisitors);
      this.store.dispatch(getWebsiteMainAnalyticsActions.loadSuccess({stats: {realtimeVisitors}}))
      return of (null);
    }
    ),
    // Continue polling indefinitely with a delay
    catchError((error) => {
      console.error('Error getting real-time visitors:', error);
      this.isPolling = false;
      return of(getWebsiteMainAnalyticsActions.loadFailure({ error }));
    }),
    // Introduce a delay before the next polling request
    delay(12000), // 10 seconds delay
    // Repeat the polling request after the delay
    switchMap(() => {
      if (!this.isPolling) {
        return EMPTY;
      }
      return this.pollRealtimeVisitors()

    })
  );
}


// Stop Polling
stopPollingRealtimeVisitors() {
  this.isPolling = false;
}
}


