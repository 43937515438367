import { VerifyBtcpayInfoComponent } from './../../components/user-dashboard/portal/view/portal-payment/integrate/method-form/btcpay-special-method/verify-btcpay-info/verify-btcpay-info.component';
import { Routes } from '@angular/router';

import { NotFoundPageComponent } from 'src/app/components/not-found-page/not-found-page.component';
import { RegisterComponent } from 'src/app/components/register/register.component';
import { WaitListComponent } from 'src/app/components/wait-list/wait-list.component';
import { environment } from 'src/environments/environment';

export const full: Routes = [
 
  {
    path: 'network',
    data: { title: 'learmo - Register' },
    loadChildren: () =>
      import(
        '../../components/affiliate-register/affiliate-register.module'
      ).then((m) => m.AffiliateRegisterModule),
  },
  {
    path: 'auth',

    loadChildren: () =>
      import('../../components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'verify',

    loadChildren: () =>
      import('../../components/user/verification.module').then(
        (m) => m.VerificationModule
      ),
  },
  {
    path: 'choose-plan',

    loadChildren: () =>
      import('../../components/choose-plan/choose-plan.module').then(
        (m) => m.ChoosePlanModule
      ),
  },
  {
    path: 'register',
    component: environment.disableRegister ? WaitListComponent : RegisterComponent
  },

  {
    path: '404',
    component: NotFoundPageComponent,
  },
  {
    path: 'verification/btcpay-integration',
    component: VerifyBtcpayInfoComponent,
  },
];
