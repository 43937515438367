import { createFeatureSelector, createSelector } from '@ngrx/store';

import { WebsiteAnalyticsState } from './website-analytics.reducer';
import { WebsiteCompoundState } from '../../../learmo.reducer';


// Create a feature selector for the 'website' feature
export const selectCompoundWebsiteState = createFeatureSelector<WebsiteCompoundState>('website');
export const selectWebsiteState = createSelector(selectCompoundWebsiteState, (state) => state.analytics);



export const selectWebsiteAggregateAnalytics = createSelector(
  selectWebsiteState,
  (state) => state.analytics
);


// export const selectWebsiteAggregateAnalyticsForDashboard = createSelector(
//   selectWebsiteState,
//   (state) => [
//     {
//       label: 'Visitors',
//       value: state?.analytics?.visitors,
//     },
//     {
//       label: 'Visits',
//       value: state?.analytics?.visits,
//     },
//   ]
// );

export const isWebsiteAggregateAnalyticsLoaded = createSelector(selectWebsiteState, (state) => state.analyticsLoaded);
export const isWebsiteAggregateAnalyticsLoadingError = createSelector(selectWebsiteState, (state) => state.analyticsError);


