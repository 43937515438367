import { ProductItem } from '../../../../components/user-dashboard/product/models/product';
import { SharedProductService } from '../../../../components/user-dashboard/product/shared-product.service';
import { Router } from '@angular/router';
import { SharedOfferService } from '../../../../components/user-dashboard/offer/shared-offer.service';
import { Component, Input, OnInit } from '@angular/core';
import { SortingTypeModel, FilteringTypeModel } from 'src/app/models/portal';

import {
  trigger,
  transition,
  style,
  state,
  animate,
} from '@angular/animations';
import { listAnimation } from 'src/app/shared/animations/animations';

import { LocalPaginationConfig } from 'src/app/models/owner-dashboard';
import { Product_Type_Enum, TransactionsListResponse } from 'src/app/models/billing-log';



@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.css'],
  animations: [
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition('void => true', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateX(250px)', opacity: 0 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    listAnimation,
  ],
})
export class TransactionsListComponent implements OnInit {
  @Input() headless: boolean = false;
  transactionsList: TransactionsListResponse = {
    transactions: [],
    countInCurrentPage: 0,
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
  };
  @Input('transactionsList') set setContactsList(value: TransactionsListResponse) {
    this.transactionsList = value;
    this.paginationConfig;
  }
  @Input() loaded: boolean = false;

  

  paginationConfig: LocalPaginationConfig = new LocalPaginationConfig({
    currentPage: 1,
    startIndex: 0,
    numberInPage: 8,
    endIndex: 8,
  });
  // Pagination

  // Sort And Filtering Features
  searchWord: string = '';
  sortObject: SortingTypeModel = {
    displayName: 'Newest',
    column: 'createdAt',
    order: 'dsc',
  };

  filterObject: FilteringTypeModel = {
    index: 0,
    displayName: 'All',
    filters: [],
  };
  filterTypes: FilteringTypeModel[] = [
    {
      index: 0,
      displayName: 'All',
      filters: [],
    },
    {
      index: 1,
      displayName: 'Subscription',
      filters: [{column: 'productType', value: Product_Type_Enum.subscription}],
    },
    {
      index: 2,
      displayName: 'Domain',
      filters: [{column: 'productType', value: Product_Type_Enum.domain}],
    },
  ];

  sortTypes: SortingTypeModel[] = [
    { displayName: 'Newest', column: 'createdAt', order: 'dsc' },
    { displayName: 'Oldest', column: 'createdAt', order: 'asc' },
    { displayName: 'Recently Updated', column: 'updatedAt', order: 'dsc' },
    { displayName: 'A-Z Title', column: 'name', order: 'asc' },
    { displayName: 'Z-A Title', column: 'name', order: 'dsc' },
  ];

  constructor(
    public sharedProductService: SharedProductService,
    private router: Router
  ) {}

  async ngOnInit() {
    // this.offersList = await this.sharedOfferService.getOffers();
  }

  // Sort And Filtering Features
  sortTypeChange(sortObject: SortingTypeModel) {
    this.sortObject = sortObject;
  }

  filterTypeChange(filterObject: FilteringTypeModel) {
    //
    this.filterObject = filterObject;
    this.paginationConfig.resetInitialValue();
  }

  resetPagination() {}


  onSearchWordChange(value: any) {
    this.searchWord = value;
  }

  consoleHandler(data: any) {}
}
