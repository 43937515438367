import { Action, createReducer, on } from '@ngrx/store';
import { WebsiteModel } from '../../../../components/user-dashboard/portal/portal.model';

import { NotificationElement } from '../../../../models/owner-dashboard';


import { forgetSession } from '../user/auth/auth.actions';
import { SystemPlansActions } from './subscription.actions';

export interface SubscriptionState {
  systemPlans
  loaded: boolean;
  error: any;
}

const initialState: SubscriptionState = {
  systemPlans: [],
  loaded: false,
  error: null,
};

export const subscriptionReducer = createReducer(
  initialState,
  on(SystemPlansActions.load, (state) => ({ ...state, loaded: false, error: null })),
  on(SystemPlansActions.loadSuccess, (state, { plans }) => {
    return     {
      ...state,
      systemPlans: plans,
      loaded: true,
    }
  }),

  on(SystemPlansActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),

  on(forgetSession, (state) => initialState)
);
