import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { StorageService } from './storage.service';
import { OpenAI } from 'openai';
import groupChatsByDate from './utils';

export interface Message {
  id: string;
  from: 'user' | 'bot';
  type: 'text' | 'image';
  text: string;
  links?: Array<{ title: string; url: string }>;
  complete?: boolean;
  isHidden: boolean;
  partToShow: string;
}

export type ModelsTypeList =
  | string
  | 'yejingfu_Meta-Llama-3.1-8B-Instruct-FP8-128K';
export interface Chat {
  id: string;

  deactivated: boolean;
  model: ModelsTypeList;
  title: string;
  messages: Message[];
  date: Date;
}

@Injectable({ providedIn: 'root' })
export class ChatService {
  private readonly OPENAI_API_URL =
    'https://ai-beta.learmo.dev/v1/chat/completions';
  //private readonly EE_API_URL = "https://api.embedelite.com";
  private readonly EE_API_URL = 'https://api.dev.embedelite.com';

  currentChat: Chat = {
    id: '',
    deactivated: false,
    model: 'yejingfu_Meta-Llama-3.1-8B-Instruct-FP8-128K',
    title: '',
    date: new Date(),
    messages: [],
  };

  chats: Chat[] = [];
  chatGroups: { title: string; chats: Chat[] }[] = [];

  // private currentChatSubject = new BehaviorSubject<Chat>(this.chats[0]);
  // currentChat = this.currentChatSubject.asObservable();

  private chatsVisibility = new BehaviorSubject(false); // set initial state to 'false'
  currentVisibility = this.chatsVisibility.asObservable();

  constructor(private storageService: StorageService) {
    // Try to load chats from local storage during service initialization
    const storedChats = this.storageService.getItem<Chat[]>('chats');
    if (storedChats) {
      this.refreshChats(storedChats);
      // this.currentChatSubject.next(this.chats[0]);
      this.switchChat(this.chats[0]);
    }
  }

  refreshChats(chats: Chat[] = this.chats) {
    this.chats = chats;
    this.chatGroups = groupChatsByDate(this.chats);
  }

  toggleChatsVisibility() {
    this.chatsVisibility.next(!this.chatsVisibility.value);
  }

  hideChatsVisibility() {
    this.chatsVisibility.next(false);
  }

  showChatsVisibility() {
    this.chatsVisibility.next(true);
  }

  getChats(): Observable<Chat[]> {
    return of(this.chats);
  }

  updateChatConfig(
    chatId: string,
    title?: string,
    model?:
      | 'yejingfu_Meta-Llama-3.1-8B-Instruct-FP8-128K'
      | 'gpt-4'
      | 'gpt-4-turbo-preview'
      | 'gpt-4o'
      | 'gpt-4o-mini'
  ): void {
    const chatIndex = this.chats.findIndex((chat) => chat.id === chatId);
    if (chatIndex < 0) {
      console.error(`No chat found with id: ${chatId}`);
      return;
    }

    if (!!model) {
      this.chats[chatIndex].model = model;
    }
    if (!!title) {
      this.chats[chatIndex].title = title;
    }
    this.storageService.setItem('chats', this.chats);
  }

  switchChat(chat: Chat) {
    // this.currentChatSubject.next(chat);
    this.currentChat = chat;
    this.scrollToChatEnd();
  }

  selectChat(chat: Chat) {
    this.switchChat(chat);
    this.hideChatsVisibility();
  }

  setChatTitle(chatId: string, title: string): void {
    const chatIndex = this.chats.findIndex((chat) => chat.id === chatId);
    if (chatIndex < 0) {
      console.error(`No chat found with id: ${chatId}`);
      return;
    }
    this.chats[chatIndex].title = title;
    this.storageService.setItem('chats', this.chats);
  }

  addChat(title: string, firstMessageBody: string, selectAfterCreate: boolean = true): Chat {
    const defaultModel = 'yejingfu_Meta-Llama-3.1-8B-Instruct-FP8-128K';

    let newChat: Chat = {
      id: this.generateId(), // Implement a method for generating unique id
      deactivated: false,
      model: defaultModel,
      title: title,
      date: new Date(),
      messages: [],
    };
    this.chats.unshift(newChat);
    this.storageService.setItem('chats', this.chats);

    this.refreshChats();
    if (selectAfterCreate) {
      this.selectChat(newChat);
    }

    const userPrompt = firstMessageBody;

    const instructionsTemplate = `
    I want you to act as a helpful ai assistant for course creator, your name is Learmo Ai. Your task is to provide a detailed and helpful answers for the content creators The responses should be concise and easy to read.
    `;

    const currentDate = new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });

    const finalPrompt = `
    <|begin_of_text|><|start_header_id|>system<|end_header_id|>

    Cutting Knowledge Date: June 2024
    Today Date: ${currentDate}

    ${instructionsTemplate}<|eot_id|><|start_header_id|>user<|end_header_id|>

    ${userPrompt}<|eot_id|><|start_header_id|>assistant<|end_header_id|>
    `;
    this.sendMessage(newChat.id, finalPrompt, defaultModel, true, userPrompt);

    return newChat;
  }

  deleteChat(chatId: string): void {
    const index = this.chats.findIndex((c) => c.id === chatId);

    if (index !== -1) {
      this.chats.splice(index, 1);

      // Update the BehaviorSubject with currentChat data
      if (this.currentChat.id === chatId && this.chats.length) {
        this.currentChat = this.chats[0];
      }

      // Update the storage
      this.storageService.setItem('chats', this.chats);
    }
  }

  // Implementation for generating unique id
  private generateId(): string {
    const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    const randomValue = crypto.getRandomValues(new Uint8Array(4)).join('');
    return `${timestamp}-${randomValue}`;
  }

  sendMessage(
    chatId: string,
    message: string,
    model:
      | 'yejingfu_Meta-Llama-3.1-8B-Instruct-FP8-128K'
      | 'gpt-4'
      | 'gpt-4-turbo-preview'
      | 'gpt-4o'
      | 'dalle3'
      | 'gpt-4o-mini' = 'yejingfu_Meta-Llama-3.1-8B-Instruct-FP8-128K',
    isHidden: boolean = false,
    partToShow: string = ''
  ): void {
    const chatIndex = this.chats.findIndex((chat) => chat.id === chatId);
    if (chatIndex < 0) {
      console.error(`No chat found with id: ${chatId}`);
      return;
    }

    const userMessage: Message = {
      id: this.generateId(),
      from: 'user',
      text: message,
      type: 'text',
      isHidden: isHidden,
      partToShow
    };
    this.chats[chatIndex].messages.push(userMessage);

    // if (this.chats[chatIndex].messages[0].from === 'user') {
    //   const firstMessage = this.chats[chatIndex].messages[0].text;
    //   const title =
    //     firstMessage.length > 16
    //       ? `${firstMessage.slice(0, 16)}...`
    //       : firstMessage;
    //   this.setChatTitle(chatId, title);
    // }

    let history = this.chats[chatIndex].messages.map((msg) => ({
      role: msg.from === 'user' ? 'user' : 'assistant',
      content: msg.text,
    }));

    let oai_api_key = this.storageService.getItem<string>('oai_api_key');
    this.sendMessageOAI(
      chatIndex,
      oai_api_key,
      model,
      message,
      history,
      isHidden
    )
      .catch((err) => {
        console.error('Failed to send message to OAI:', err);
      })
      .then(() => {
        if (history.length < 3) return;
        const context = `${history.slice(2)
          .map((msg) => msg.content)
          .join('\n')}\n ${message}`;

        !isHidden && this.generateChatTitle(chatId, `${context}`);
      });

    this.scrollToChatEnd();
  }

  generateChatTitle(chatId: string, context: string) {
    const prompt = `
          
    <|begin_of_text|><|start_header_id|>system<|end_header_id|>

    ---BEGIN Conversation---
    ${context}
    ---END Conversation---

    You are a helpful assistant<|eot_id|><|start_header_id|>user<|end_header_id|>

    Generate a concise title for the conversation in just one short sentence start with emoji and maximum 3 words without any symbols.<|eot_id|><|start_header_id|>assistant<|end_header_id|>`;
    let generatedTitle = '';
    this.generateCompelation(
      'NA',
      'yejingfu_Meta-Llama-3.1-8B-Instruct-FP8-128K',
      `${prompt}`
    ).subscribe((stream) => {
      console.log(stream);
      generatedTitle += stream;
      if (!!generatedTitle.length) {
        this.updateChatConfig(chatId, generatedTitle);
      }
    });
  }

  scrollToChatEnd() {
    setTimeout(() => {
      let element = document.getElementById('chat-area');
      element.scrollTop = element.scrollHeight;
    }, 100);
  }
  async sendMessageOAI(
    chatIndex: number,
    oai_api_key: any,
    model: string,
    message: string,
    history: any[],
    isHidden: boolean = false,
    partToShow: string = ''
  ) {
    const openai = new OpenAI({
      apiKey: oai_api_key,
      dangerouslyAllowBrowser: true,
      maxRetries: 5,
      defaultQuery: {},
      baseURL: 'https://ai-beta.learmo.dev/v1',
    });
    openai.chat.completions
      .create({
        model: model,
        messages: [...history, { role: 'user', content: message }],
        stream: true,
      })
      .then(async (stream) => {
        const decoder = new TextDecoder('utf-8');

        let receivedString = '';
        let isNewMessage = true;
        let newMessage: Message;
        newMessage = {
          id: this.generateId(),
          from: 'bot',
          text: '',
          complete: false,
          type: 'text',
          isHidden,
          partToShow
        };

        for await (const chunk of stream) {
          receivedString += chunk.choices[0].delta.content;
          if (chunk.choices[0].finish_reason === 'stop') {
            receivedString += '\n';
          }
          const newLineIndex = receivedString.indexOf('\n');
          const line = receivedString.slice(0, newLineIndex);
          receivedString = receivedString.slice(newLineIndex + 1);

          if (chunk.choices && chunk.choices[0] && chunk.choices[0].delta) {
            if (isNewMessage) {
              this.chats[chatIndex].messages.push(newMessage);
              isNewMessage = false;
            } else {
              let delta = chunk.choices[0].delta.content;
              if (delta && delta !== '') {
                newMessage.text += delta;
                newMessage.complete = chunk.choices[0].finish_reason === 'stop';
              }
            }

            this.storageService.setItem('chats', this.chats);
          }
          this.scrollToChatEnd();
        }
      });
  }

  generateCompelation(oai_api_key: any, model: string, message: string) {
    return new Observable<string>((observer) => {
      const prompt = `${message}`;

      const openai = new OpenAI({
        apiKey: oai_api_key,
        dangerouslyAllowBrowser: true,
        maxRetries: 5,
        defaultQuery: {},
        baseURL: 'https://ai-beta.learmo.dev/v1',
      });
      const steam = openai.completions
        .create({
          model: model,
          prompt: prompt,
          temperature: 0.5,
          stream: true,
        })
        .then(async (stream) => {
          for await (const chunk of stream) {
            observer.next(chunk.choices[0].text);
          }
        });
    });
  }

  updateMessage(chatId: string, updatedMessage: Message) {
    // Find the chat based on chatId
    const chatIndex = this.chats.findIndex((chat) => chat.id === chatId);

    if (chatIndex < 0) {
      console.error(`No chat found with id: ${chatId}`);
      return;
    }

    // Within the found chat, find the message index using updatedMessage.id
    const messageIndex = this.chats[chatIndex].messages.findIndex(
      (msg) => msg.id === updatedMessage.id
    );

    if (messageIndex < 0) {
      console.error(`No message found with id: ${updatedMessage.id}`);
      return;
    }

    this.chats[chatIndex].messages[messageIndex] = updatedMessage;
    this.storageService.setItem('chats', this.chats);

    this.refreshChatAfterEdit(chatId, updatedMessage.id).catch((err) =>
      console.error('Failed to refresh chat:', err)
    );
  }

  async refreshChatAfterEdit(
    chatId: string,
    updatedMessageId: string
  ): Promise<void> {
    const chatIndex = this.chats.findIndex((chat) => chat.id === chatId);
    if (chatIndex < 0) {
      console.error(`No chat found with id: ${chatId}`);
      return;
    }

    const messageIndex = this.chats[chatIndex].messages.findIndex(
      (msg) => msg.id === updatedMessageId
    );

    this.chats[chatIndex].messages.splice(messageIndex + 1);

    let history = this.chats[chatIndex].messages.map((msg) => ({
      role: msg.from === 'user' ? 'user' : 'assistant',
      content: msg.text,
    }));

    const model = this.chats[chatIndex].model;
    // const productId = this.chats[chatIndex].product_id;

    const lastMessage = history[history.length - 1].content;

    // Fetch new sequence of responses based on the updated history
    if (lastMessage) {
      let oai_api_key =
        this.storageService.getItem<string>('oai_api_key') || 'NA';
      await this.sendMessageOAI(
        chatIndex,
        oai_api_key,
        model,
        lastMessage,
        history
      );
    }
  }
}
