import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Input,
  ViewChild,
} from "@angular/core";

import { Chat, ChatService, Message, ModelsTypeList } from "../../services/chat.service";


@Component({
  selector: "app-chat-input",
  templateUrl: "./chat-input.component.html",
  styleUrls: ["./chat-input.component.css"],
})
export class ChatInputComponent {
  
  @Input() model: ModelsTypeList = "yejingfu_Meta-Llama-3.1-8B-Instruct-FP8-128K";
  @Input() deactivated: boolean = false;
  @Output() openViewer = new EventEmitter<string>();
  // @Output() newMessage = new EventEmitter<any>();
  @Output() updateChatConfig = new EventEmitter<any>();
  @ViewChild("textarea") textArea!: ElementRef;

  message: string;
  showChatConfig: boolean = false;
  showExtraProduct: boolean = false;
  // currentChat: Chat = {
  //   id: "",
  //   deactivated: false,
  //   model: "yejingfu_Meta-Llama-3.1-8B-Instruct-FP8-128K",
  //   title: "",
  //   date: new Date(),
  //   messages: [],
  // };

  

  constructor(private chatService: ChatService) {
    // this.chatService.currentChat.subscribe((chat) => {
    //   this.currentChat = chat;
    //   console.log(this.currentChat);
    //   this.model = chat.model;
      
    //   // update the drop down with the current model
    // });
    this.message = "";
    // if product id is not fr, de, or es then show extra product
    
  }

  ngOnChanges() {
  }

  // async fetchProducts() {
  //   this.products = await this.productService.listProducts();
  // }

  handleMenuItemClick(item: any) {
    console.log(`Parent received item: ${item}`);
    this.model = item;
    this.updateChatConfigTrigger();
  }



  toggleChatConfig() {
    this.showChatConfig = !this.showChatConfig;
  }

  sendMessage() {
    if (this.deactivated) return;
    if (!this.message.trim()) return;
    if (!this.chatService?.currentChat?.id) {
      this.chatService.addChat("New Chat",this.message.trim(), true);
    }
    else {
    
    let msg_info = {
      id: this.chatService.currentChat.id,
      message: this.message.trim(),
      model: this.model,
    };
    this.updateMessages(msg_info);
    }
    this.message = "";
    setTimeout(() => this.adjustTextarea(null), 0); // Adjust textarea after message is sent
  }

  updateMessages(msgInfo: any) {
    this.chatService.sendMessage(
      msgInfo.id,
      msgInfo.message,
      msgInfo.model,
    );
  }


  adjustTextarea(event: any) {
    setTimeout(() => {
      const textarea: any = event
        ? event.target
        : document.querySelector("textarea");
      if (textarea.value === "") {
        // Reset textarea size if there's no content
        textarea.style.height = "auto";
      } else if (textarea.scrollHeight < 300) {
        // Increase height if scrollHeight is under 150.
        textarea.style.height = `${textarea.scrollHeight}px`;
      } else if (textarea.clientHeight < 300) {
        // If content is larger but textarea's visible height is less, set it to 150
        textarea.style.height = `300px`;
      }
    }, 0);
  }

  selectModelOption(model: any) {
    if (this.deactivated) return;
    this.model = model.value;
    this.updateChatConfigTrigger();
  }

 


  updateChatConfigTrigger() {
    if (this.deactivated) return;
    let config = {
      model: this.model,
    };
    console.log(config);
    this.updateChatConfig.emit(config);
  }
}
