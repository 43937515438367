import { Component, Input, input, OnInit } from '@angular/core';
import { SharedProfileService } from '../../../shared-profile.service';
import { ProfileUiControlService } from 'src/app/shared/services/profile-ui-control.service';

@Component({
  selector: 'app-profile-pic-view',
  templateUrl: './profile-pic-view.component.html',
  styleUrls: ['./profile-pic-view.component.css']
})
export class ProfilePicViewComponent implements OnInit {
  @Input() mode: 'symbol' | 'img' = 'img';
  @Input() symbolSize: {default: number, md?: number, lg?: number} = {default: 35, md: 40};
  @Input() customSymbolClasses: string = '';
  @Input() showOnline: boolean = false;
  constructor(public sharedProfileService: SharedProfileService, public profileUiService: ProfileUiControlService) { }

  ngOnInit() {
  }

}
