import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgxTippyInstance, NgxTippyProps, NgxTippyService } from 'ngx-tippy-wrapper';
import { Tippy } from 'tippy.js';
import { ChatService } from './services/chat.service';

@Component({
  selector: 'app-chat-with-ai',
  templateUrl: './chat-with-ai.component.html',
  styleUrls: ['./chat-with-ai.component.css']
})
export class ChatWithAiComponent implements OnInit, AfterViewInit {
  showGuidance: boolean = false;
  @Input() customButton = false;
  // instance: NgxTippyInstance;
  showToggleIcon: boolean = true;
  // tippyOptions: NgxTippyProps = {
    
  //   followCursor: false,
  //   trigger: 'manual',  
  //   placement: 'top-start',
  //   hideOnClick: false,
  //   interactive: true,
  //   arrow: false,
  //   theme: 'chat-with-ai',
    
  //   onClickOutside: (instance, event) => {
  //       this.hideAi();
  //   },
  // }
  
  isOpen: boolean = false;
  constructor(private ngxTippyService: NgxTippyService, public chatService: ChatService) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showButtonGuidance()  
    }, 1000);
    
  }


  showButtonGuidance(duration: number = 5000, callback: () => void = this.showTippyTemporary) {
    this.showGuidance = true;
    setTimeout(() => {
      this.showGuidance = false;
      setTimeout(() => {
        callback();  
      }, 500);
    }, duration);
  }

  showTippyTemporary = (duration: number = 8000) => {
    const instance = this.ngxTippyService.getInstance('chatWithLearmoAiButton');
    instance.show()

    setTimeout(() => {
      instance.hide();
      
    }, duration);
  }
  ngOnInit() {
  }
  

  showAi = () => {
      // instance.show();
      this.isOpen = true;
    }
  

  hideAi = () => {
    // instance.hide();
    this.isOpen = false;
  }

  toggleAi = () => {
    // let instance = this.ngxTippyService.getInstance('chat-with-ai');
    // console.log(instance.state)
    if (this.isOpen) {
      this.hideAi();
    }
    else {
      this.showAi();
    }
  }


  toggleSidebarVisibility() {
    this.chatService.toggleChatsVisibility();
  }
}
