// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* :host{
    display: block;
    width: 100%;
    height: 100%;
} */

img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile/profile-main-page/profile-pic-input/profile-pic-view/profile-pic-view.component.css"],"names":[],"mappings":"AAAA;;;;GAIG;;AAEH;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":["/* :host{\n    display: block;\n    width: 100%;\n    height: 100%;\n} */\n\nimg{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
