import { ProductItem, ProductModel, ProductTypeModel } from '../../../../../components/user-dashboard/product/models/product';
import { Router } from '@angular/router';
import { SharedProductService } from '../../../../../components/user-dashboard/product/shared-product.service';

import { Component, OnInit, Input } from '@angular/core';
import { DropdownItem } from '../../../dynamic-dropdown/dynamic-dropdown.component';
import { CustomerItem } from 'src/app/models/system.base.models';
import { BillingLogItem } from 'src/app/models/billing-log';
import { PaymentMethodViewTypeEnum } from '../../../payment-method-view/payment-method-view.component';



@Component({
  selector: 'app-transactions-list-item',
  templateUrl: './transactions-list-item.component.html',
  styleUrls: ['./transactions-list-item.component.css']
})
export class TransactionsListItemComponent implements OnInit {
  paymentMethodViewTypeEnum = PaymentMethodViewTypeEnum
  @Input() item: BillingLogItem;

  onClickRouter:string | any[];
  dropdownItems: DropdownItem [] = [

  ]
  constructor(private router: Router) { }

  ngOnInit() {
    // switch (this.product.type.name) {
    //   case 'course':
    //     this.generateCourseDropdown()
    //     break;
    //     case 'downloadable':
    //     this.generateDownloadableDropdown()
    //     break;
    //   default:
    //     break;
    // }

  }

  // generateCourseDropdown() {
  //   this.onClickRouter = ['/dashboard', 'product', this.product.id, 'course','builder'];
  //   this.dropdownItems = [
  //     {
  //       label: 'Course Builder',
  //       iconSvg: 'assets/images/svg/system/course/builder.svg',
  //       colorSet: 'gray-700',
  //       colorSetHover: 'primary',
  //       routerLink: ['/dashboard', 'product', this.product.id, 'course','builder']
  //     },
  //     {
  //       label: 'Edit Details',
  //       iconSvg: 'assets/images/svg/system/edit.svg',
  //       colorSet: 'gray-700',
  //       colorSetHover: 'primary',
  //       routerLink: ['/dashboard', 'product', this.product.id, 'edit']
  //     },
  //     {
  //       label: 'Duplicate',
  //       iconSvg: 'assets/images/svg/system/copy.svg',
  //       colorSet: 'gray-700',
  //       colorSetHover: 'primary',
  //       action : () => {
  //         this.cloneProduct(this.product)
  //       },
  //     },
  //     undefined,
  //     {
  //       label: 'Delete',
  //       iconSvg: 'assets/images/svg/system/trash.svg',
  //       colorSet: 'danger',
  //       colorSetHover: 'danger',
  //       action: ()=>{this.deleteProduct(this.product)}
  //     }
  //   ]
  // }

  // generateDownloadableDropdown() {
  //   this.onClickRouter = ['/dashboard', 'product', this.product.id, 'downloadable','content']
  //   this.dropdownItems = [
  //     {
  //       label: 'Content',
  //       iconSvg: 'assets/images/svg/system/preview.svg',
  //       colorSet: 'gray-700',
  //       colorSetHover: 'primary',
  //       routerLink: ['/dashboard', 'product', this.product.id, 'downloadable','content']
  //     },
  //     {
  //       label: 'Edit Details',
  //       iconSvg: 'assets/images/svg/system/edit.svg',
  //       colorSet: 'gray-700',
  //       colorSetHover: 'primary',
  //       routerLink: ['/dashboard', 'product', this.product.id, 'edit']
  //     },
  //     {
  //       label: 'Duplicate',
  //       iconSvg: 'assets/images/svg/system/copy.svg',
  //       colorSet: 'gray-700',
  //       colorSetHover: 'primary',
  //       action : () => {
  //         this.cloneProduct(this.product)
  //       },
  //     },
  //     undefined,
  //     {
  //       label: 'Delete',
  //       iconSvg: 'assets/images/svg/system/trash.svg',
  //       colorSet: 'danger',
  //       colorSetHover: 'danger',
  //       action: ()=>{this.deleteProduct(this.product)}
  //     }
  //   ]
  // }

  // deleteProduct = (product: ProductItem) => {
  //   this.sharedProductService.deleteProductAlert(product);
  // }
  // cloneProduct = (product: ProductItem) => {
  //   this.sharedProductService.cloneProductAlert(product);
  // }


}
