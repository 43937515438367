// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.max-w-3-10 {
    max-width: 40%;
  }

  ::ng-deep .markdown-content {
    /* font-size: 1rem; */
    /* line-height: 1.25rem; */
  }
  ::ng-deep .markdown-content p {
    margin-bottom: 0.5rem;
  }
  ::ng-deep .markdown-content h1,
  ::ng-deep .markdown-content h2,
  ::ng-deep .markdown-content h3,
  ::ng-deep .markdown-content h4,
  ::ng-deep .markdown-content h5,
  ::ng-deep .markdown-content h6 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/chat-with-ai/partials/chat-area/partials/bot-message/bot-message.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;EAChB;;EAEA;IACE,qBAAqB;IACrB,0BAA0B;EAC5B;EACA;IACE,qBAAqB;EACvB;EACA;;;;;;IAME,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;EACvB","sourcesContent":[".max-w-3-10 {\n    max-width: 40%;\n  }\n\n  ::ng-deep .markdown-content {\n    /* font-size: 1rem; */\n    /* line-height: 1.25rem; */\n  }\n  ::ng-deep .markdown-content p {\n    margin-bottom: 0.5rem;\n  }\n  ::ng-deep .markdown-content h1,\n  ::ng-deep .markdown-content h2,\n  ::ng-deep .markdown-content h3,\n  ::ng-deep .markdown-content h4,\n  ::ng-deep .markdown-content h5,\n  ::ng-deep .markdown-content h6 {\n    font-size: 1rem;\n    font-weight: bold;\n    margin-top: 1rem;\n    margin-bottom: 0.5rem;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
