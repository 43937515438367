// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ai Config */


/* float Config */
.float-button-container * {
    transition: all 0.12s ease-in-out;
  }
  .float-button-container {
    position: fixed;
    z-index: 20;
    bottom: 20px;
    right: 20px;
  }
  
  .float-button-container .red-notification-dot {
    position: absolute;
    top: 0;
    right: 0px;
    width: 12px !important;
    height: 12px !important;
    border: 1px solid #fff;
  }
  
  .dynamic-gradient-bg {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .float-button-container button:hover {
    transform: scale(1.1) translateY(-5px);
  }
  
  .float-button-container[aria-describedby="ngb-popover-0"] button,
  .float-button-container[aria-describedby="ngb-popover-0"] button:hover {
    transform: scale(0.9) translateY(5px);
  }
  
  `, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/chat-with-ai/chat-with-ai.component.css"],"names":[],"mappings":"AAAA,cAAc;;;AAGd,iBAAiB;AACjB;IACI,iCAAiC;EACnC;EACA;IACE,eAAe;IACf,WAAW;IACX,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,UAAU;IACV,sBAAsB;IACtB,uBAAuB;IACvB,sBAAsB;EACxB;;EAEA;IACE,uEAAuE;IACvE,0BAA0B;IAC1B,qCAAqC;EACvC;;EAEA;IACE;MACE,2BAA2B;IAC7B;IACA;MACE,6BAA6B;IAC/B;IACA;MACE,2BAA2B;IAC7B;EACF;;EAEA;IACE,sCAAsC;EACxC;;EAEA;;IAEE,qCAAqC;EACvC","sourcesContent":["/* Ai Config */\n\n\n/* float Config */\n.float-button-container * {\n    transition: all 0.12s ease-in-out;\n  }\n  .float-button-container {\n    position: fixed;\n    z-index: 20;\n    bottom: 20px;\n    right: 20px;\n  }\n  \n  .float-button-container .red-notification-dot {\n    position: absolute;\n    top: 0;\n    right: 0px;\n    width: 12px !important;\n    height: 12px !important;\n    border: 1px solid #fff;\n  }\n  \n  .dynamic-gradient-bg {\n    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);\n    background-size: 400% 400%;\n    animation: gradient 15s ease infinite;\n  }\n\n  @keyframes gradient {\n    0% {\n      background-position: 0% 50%;\n    }\n    50% {\n      background-position: 100% 50%;\n    }\n    100% {\n      background-position: 0% 50%;\n    }\n  }\n  \n  .float-button-container button:hover {\n    transform: scale(1.1) translateY(-5px);\n  }\n  \n  .float-button-container[aria-describedby=\"ngb-popover-0\"] button,\n  .float-button-container[aria-describedby=\"ngb-popover-0\"] button:hover {\n    transform: scale(0.9) translateY(5px);\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
