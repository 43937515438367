// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  
    .sidebar-mobile-overlay {
      position: fixed;
      top: 48px;
      left: 0;
      height: 100%;
      width: 80%; /* Adjust this value based on your design requirements */
      background-color: #ffffff; /* Or whichever your desired background color is */
      z-index: 100; /* Ensures it floats above other content */
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
    }

    .sidebar-mobile-overlay.show {
      transform: translateX(0);
    }
  

  @media (min-width: 768px) {
    .sidebar-normal {
      /* Define styles for larger screens if necessary, or rely on default styles */
    }
  }`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/chat-with-ai/partials/chat/chat.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,gBAAgB;EAClB;;;IAGE;MACE,eAAe;MACf,SAAS;MACT,OAAO;MACP,YAAY;MACZ,UAAU,EAAE,wDAAwD;MACpE,yBAAyB,EAAE,kDAAkD;MAC7E,YAAY,EAAE,0CAA0C;MACxD,4BAA4B;MAC5B,sCAAsC;IACxC;;IAEA;MACE,wBAAwB;IAC1B;;;EAGF;IACE;MACE,6EAA6E;IAC/E;EACF","sourcesContent":[":host {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n  }\n\n  \n    .sidebar-mobile-overlay {\n      position: fixed;\n      top: 48px;\n      left: 0;\n      height: 100%;\n      width: 80%; /* Adjust this value based on your design requirements */\n      background-color: #ffffff; /* Or whichever your desired background color is */\n      z-index: 100; /* Ensures it floats above other content */\n      transform: translateX(-100%);\n      transition: transform 0.3s ease-in-out;\n    }\n\n    .sidebar-mobile-overlay.show {\n      transform: translateX(0);\n    }\n  \n\n  @media (min-width: 768px) {\n    .sidebar-normal {\n      /* Define styles for larger screens if necessary, or rely on default styles */\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
