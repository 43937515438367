import { forgetSession } from './../../user/register/register.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { getWebsiteMainAnalyticsActions } from './website-analytics.actions';


export interface WebsiteAnalyticsState {
  analytics: any;
  // {
  //   aggregateAnalytics: { results: any }, 
  //   bestCountry: { results: { country: string, visitors: number }[] }, 
  //   realtimeVisitors: number 
  // };
  analyticsLoaded: boolean;
  analyticsError: any;
}



const initialState: WebsiteAnalyticsState = {
  analytics: null,
  analyticsLoaded: false,
  analyticsError: null
};

export const websiteAnalyticsReducer = createReducer(
  initialState,  // Analytics
  on(getWebsiteMainAnalyticsActions.load, (state) => ({
    ...state,
    analyticsLoaded: false,
    analyticsError: null
  })),
  on(getWebsiteMainAnalyticsActions.loadSuccess, (state, {stats}) => ({
    ...state,
    analytics: {...state.analytics , ...stats},
    analyticsLoaded: true
  })),

  on(getWebsiteMainAnalyticsActions.loadFailure, (state, {error}) => ({
    ...state,
    analyticsError: error
  })),


  on(forgetSession, (state) => initialState)
);
