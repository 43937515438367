import { Component, OnInit } from "@angular/core";
import { Chat, ChatService, Message } from "../../services/chat.service";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"],
})
export class ChatComponent implements OnInit {
 

  isViewerVisible = false;
  showConfig = false;
  docUrl = "";
  currentVisibility = false;

  constructor(public chatService: ChatService) {
    
  }

  ngOnInit() {
    this.chatService.currentVisibility.subscribe(
      (state) => {
        this.currentVisibility = state
        console.log("State", state)
      }
    );
  }

  updateShowConfig(newShowConfigValue: boolean) {
    this.showConfig = newShowConfigValue;
  }

  openViewer(url: string) {
    this.docUrl = url;
    this.isViewerVisible = true;
  }

  closeViewer() {
    this.isViewerVisible = false;
  }

  updateChatConfig(config: any) {
    this.chatService.updateChatConfig(
      this.chatService.currentChat.id,
      config.mode,
      config.model,
    );
  }

 
  closeSidebar() {
    // Check if we are on a mobile device by window width
    
      this.chatService.hideChatsVisibility();
    
  }

  handleMessageEdit(updatedMessage: Message): void {
    this.chatService.updateMessage(this.chatService.currentChat.id, updatedMessage);
  }
}
