import { LearmoRouteReuseStrategy } from './learmo-router-reuse-strategy';
import { PlaygroundComponent } from './components/playground/playground.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, RouteReuseStrategy } from '@angular/router';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { LoginComponent } from './components/auth/login/login.component';
// import { SessionResolver } from './resolvers/session';
import { PortalComponent } from './components/user-dashboard/portal/portal.component';
import { TopMenuComponent } from './shared/layout/top-menu/top-menu.component';
import { VerificationResolver } from './resolvers/verification.resolve';
import { ResetPasswordResolver } from './resolvers/reset-password.resolve';
import { CanDeactivateGuard } from './shared/guards/nav.guard';
import { CanDeactivatePortalGuard } from './shared/guards/portalnav.guard';
import { full } from './shared/routes/full.routes';
import { FullComponent } from './shared/layout/full/full.component';
import { ProfileComponent } from './components/profile/profile-main-page/profile.component';

const routes: Routes = [
  {
    path: 'playground',
    pathMatch: 'full',
    component: PlaygroundComponent
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    data: { title: 'learmo - Dashboard' },
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
    pathMatch: 'full',
    data: { title: 'Login' },
  },
  {
    path: '',
    children: [
      {
        path: '',

        loadChildren: () =>
          import('./shared/layout/layout.module').then((m) => m.LayoutModule),
      },
      {
        path: '',
        component: FullComponent,
        data: {
          hideUiExcept: ''
        },
        children: full,
      },
    ],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

export const components = [
  UnauthorizedComponent,
  LoginComponent,
  PortalComponent,
  TopMenuComponent,
  ProfileComponent,
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'ignore',
    
}),
  ],
  exports: [RouterModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: LearmoRouteReuseStrategy },
    VerificationResolver,
    ResetPasswordResolver,
    CanDeactivateGuard,
    CanDeactivatePortalGuard,
  ],
})
export class AppRoutingModule {}
