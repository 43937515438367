import { AnalyticsService } from './shared/services/analytics.service';
import {
  NavigationEnd,
  Router,
  ActivatedRoute,
  NavigationStart,
  NavigationSkipped,
  ActivatedRouteSnapshot,
} from '@angular/router';

import { AfterViewInit, ApplicationRef, Component, OnInit } from '@angular/core';
import {
  NgbDropdownConfig,
  NgbModalConfig,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { filter, map, of, switchMap } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { followCursor } from 'tippy.js';
import {
  LearmoFeatureItemType,
  VersionCheckService,
} from './shared/services/version-check.service';
import {
  SystemUiElementsCollection,
  SystemUiMode,
  UserDashboardService,
} from './shared/services/user-dashboard.service';
import { LoggingService } from './shared/services/logging.service';
import { InOutAnimation } from './shared/animations/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [InOutAnimation],
})
export class AppComponent implements OnInit, AfterViewInit {
  defaultPageTitle = 'learmo';
  constructor(
    public userDashboardService: UserDashboardService,
    private router: Router,
    private route: ActivatedRoute,
    public dropdownConfig: NgbDropdownConfig,
    private title: Title,
    private modalConfig: NgbModalConfig,
    private tippyService: NgxTippyService,
    public versionCheckService: VersionCheckService,
    private loggingService: LoggingService,
    private analyticsService: AnalyticsService
  ) {}
  ngAfterViewInit(): void {
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd || event instanceof NavigationSkipped),
      switchMap(() => {
        const routeUiData = this.extractRouteUiData(this.route.snapshot);
        const routeTitle = this.extractRouteTitle(this.route.snapshot);
        return of({
          ...routeUiData,
          title: routeTitle
        });
      })
    )
    .subscribe((data) => {
      // Handle your data here
      console.log('Combined Route Data:', data);

      // Example usage: Set UI elements and UI mode using a service method
      this.userDashboardService.setUiMode(data.uiMode);
      this.userDashboardService.setUiElements(data.hideUiExcept, data.showUiExcept);

      if (!!data?.title && data?.title != this.defaultPageTitle) {
        this.title.setTitle(data?.title);
      }
    });

  }

  
  extractRouteTitle = (child: any) => {
    // let child = this.route.firstChild;
    
        if (!child) {
          return this.route.snapshot.data.title || this.defaultPageTitle;
        }

        while (child.firstChild) {
          child = child.firstChild;
        }

        if (child.snapshot?.data?.title) {
          return child.snapshot.data.title || this.defaultPageTitle;
        }
        return this.defaultPageTitle;
  }
  extractRouteUiData(child: ActivatedRouteSnapshot) {
      // let child = this.route.snapshot.firstChild;
        let showUiExcept: SystemUiElementsCollection = null;
        let hideUiExcept: SystemUiElementsCollection = null;
        let uiMode: SystemUiMode = null;

        while (child) {
          if (!!child?.data?.showUiExcept?.length) {
            showUiExcept = child.data.showUiExcept;
          } else if (child?.data?.showUiExcept == undefined) {
            showUiExcept = undefined;
          } else {
            showUiExcept = null;
          }

          if (!!child?.data?.hideUiExcept?.length) {
            hideUiExcept = child.data.hideUiExcept;
          } else if (child?.data?.hideUiExcept == undefined) {
            hideUiExcept = undefined;
          } else {
            hideUiExcept = null;
          }

          if (!!child?.data?.uiMode || child?.data?.uiMode?.length > 0) {
            if (child.data.uiMode instanceof Array) {
              uiMode = [...child.data.uiMode];
            } else {
              uiMode = child.data.uiMode;
            }
          }

          // Move to the next child route
          child = child.firstChild;
        }

        // Return the accumulated data wrapped in an observable
        return {
          showUiExcept: showUiExcept,
          hideUiExcept: hideUiExcept,
          uiMode: uiMode
        };
  }
  scrollTo(elementID: string, position: ScrollLogicalPosition = 'nearest') {
    (document.getElementById(elementID) as HTMLElement)?.scrollIntoView({
      behavior: 'smooth',
      block: position,
    });
  }

  ngOnInit() {
    this.tippyService.setDefaultProps({
      plugins: [followCursor],
      placement: 'auto',
      followCursor: true,
      theme: 'light',
      arrow: true,
      delay: [200, 0],
      duration: [190, 0],
      zIndex: 999999999999,

      animation: 'shift-toward-extreme',
      allowHTML: true,
    });

    this.dropdownConfig.container = 'body';
    this.dropdownConfig.autoClose = true;
    this.modalConfig.animation = false;

    // this.modalConfig.beforeDismiss = ()=>
    //   this.onCloseModalGlobal();
    // ;


   

  

    this.brandedLog();
  }

  brandedLog() {
    console.log(
      '%c🫦😂😅\n learmo%c.com💙\n%c - Monetize your knowledge! -',
      'filter: invert(1); font-size: 48px; font-weight: bolder; font-family: "tahoma"; margin-top: 20px; margin-bottom: 8px;',
      'color: #0d6efd; font-size: 28px; font-weight: thin; font-family: "tahoma"',
      'color: #B5B5C3; font-size: 16px; font-family: "tahoma"; margin-bottom: 20px;'
    );
  }
  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
