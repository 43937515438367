// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active {
  background-color: #f3f4f6;
}
.hover-group:hover .opacity-0 {
  opacity: 1 !important;
}
.custom-btn {
  height: 44px;
}


.chats-list-item {
  background-color: var(--bs-gray-200);
}


.chats-list-item:hover {
  background-color: var(--bs-primary-light);
  color: var(--bs-primary);
}

.chats-list-item.active {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  font-weight: 600;
}


.chat-list-item-container .chat-actions {
  transform: translateX(-100%);
  max-width: 0;
  overflow: hidden;
  transition: all 0.15s ease-in-out;
}

.chat-list-item-container:hover .chat-actions {
  max-width: 50px;
  transform: translateX(0);
}`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/chat-with-ai/partials/sidebar/sidebar.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,qBAAqB;AACvB;AACA;EACE,YAAY;AACd;;;AAGA;EACE,oCAAoC;AACtC;;;AAGA;EACE,yCAAyC;EACzC,wBAAwB;AAC1B;;AAEA;EACE,mCAAmC;EACnC,sBAAsB;EACtB,gBAAgB;AAClB;;;AAGA;EACE,4BAA4B;EAC5B,YAAY;EACZ,gBAAgB;EAChB,iCAAiC;AACnC;;AAEA;EACE,eAAe;EACf,wBAAwB;AAC1B","sourcesContent":[".active {\n  background-color: #f3f4f6;\n}\n.hover-group:hover .opacity-0 {\n  opacity: 1 !important;\n}\n.custom-btn {\n  height: 44px;\n}\n\n\n.chats-list-item {\n  background-color: var(--bs-gray-200);\n}\n\n\n.chats-list-item:hover {\n  background-color: var(--bs-primary-light);\n  color: var(--bs-primary);\n}\n\n.chats-list-item.active {\n  background-color: var(--bs-primary);\n  color: var(--bs-white);\n  font-weight: 600;\n}\n\n\n.chat-list-item-container .chat-actions {\n  transform: translateX(-100%);\n  max-width: 0;\n  overflow: hidden;\n  transition: all 0.15s ease-in-out;\n}\n\n.chat-list-item-container:hover .chat-actions {\n  max-width: 50px;\n  transform: translateX(0);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
